import _defineProperty from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
import _once from "lodash/once";
import _isNaN from "lodash/isNaN";
import _isEmpty from "lodash/isEmpty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import cookie from "js-cookie";
import { isJSON } from "../utils/common";

function storageFactory(setItem, getItem, clear) {
  var setStorageItem = function setStorageItem(key, value) {
    return setItem(key, value);
  };

  var getStringWithDefault = function getStringWithDefault(key, defaultItem) {
    try {
      var itemString = getItem(key);
      return _isEmpty(itemString) ? defaultItem : itemString;
    } catch (error) {
      return defaultItem;
    }
  };

  var getBooleanWithDefault = function getBooleanWithDefault(key, defaultItem) {
    try {
      var itemString = getItem(key);
      return _isEmpty(itemString) ? defaultItem : itemString === "true";
    } catch (error) {
      return defaultItem;
    }
  };

  var getNumberWithDefault = function getNumberWithDefault(key, defaultItem) {
    try {
      var itemString = getItem(key);
      var num = Number(itemString);
      return !_isNaN(num) ? num : defaultItem;
    } catch (error) {
      return defaultItem;
    }
  };

  var getJSONWithDefault = function getJSONWithDefault(key, defaultItem) {
    try {
      var itemJSON = getItem(key);

      if (!isJSON(itemJSON)) {
        return defaultItem;
      }

      return JSON.parse(itemJSON);
    } catch (error) {// NOTHING
    }

    return defaultItem;
  };

  var setStorages = {
    saveAccessToken: function saveAccessToken(value) {
      setStorageItem("accessToken", value);
    },
    saveSecret: function saveSecret(value) {
      setStorageItem("secret", value);
    },
    saveLanguage: function saveLanguage(value) {
      setStorageItem("lng", value);
    }
  };
  var getStorages = {
    accessToken: function accessToken() {
      return getStringWithDefault("accessToken", "");
    },
    secret: function secret() {
      return getStringWithDefault("secret", "");
    },
    language: function language() {
      return getStringWithDefault("lng", "ko");
    }
  };
  return _objectSpread(_objectSpread(_objectSpread({}, setStorages), getStorages), {}, {
    setItem: setItem,
    getStringWithDefault: getStringWithDefault,
    getBooleanWithDefault: getBooleanWithDefault,
    getNumberWithDefault: getNumberWithDefault,
    getJSONWithDefault: getJSONWithDefault,
    clear: clear
  });
}

export var storage = _once(function () {
  var setItem = function setItem(key, value) {
    cookie.set(key, value);
  };

  var getItem = function getItem(key) {
    var _cookie$get;

    return (_cookie$get = cookie.get(key)) !== null && _cookie$get !== void 0 ? _cookie$get : "";
  };

  var clear = function clear() {
    var keys = ["accessToken", "secret"];

    for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
      var _key = _keys[_i];
      cookie.remove(_key);
    }
  };

  return storageFactory(setItem, getItem, clear);
});