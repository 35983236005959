import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
import _styled from "@emotion/styled/base";
var __jsx = React.createElement;

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import React from "react";
import LoadingButton from "./LoadingButton";

var Container = _styled("div", process.env.NODE_ENV === "production" ? {
  target: "e1kmktbf1"
} : {
  target: "e1kmktbf1",
  label: "Container"
})(process.env.NODE_ENV === "production" ? {
  name: "odg1f",
  styles: "display:flex;flex:1;flex-direction:row;justify-content:space-around"
} : {
  name: "odg1f",
  styles: "display:flex;flex:1;flex-direction:row;justify-content:space-around",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9wYWNrYWdlcy9oZW5lc2lzLXdhbGxldC1kYXNoYm9hcmQvc3JjL2NvbXBvbmVudHMvQnV0dG9uL0JvdHRvbUJ1dHRvbi50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBYzRCIiwiZmlsZSI6Ii9wYWNrYWdlcy9oZW5lc2lzLXdhbGxldC1kYXNoYm9hcmQvc3JjL2NvbXBvbmVudHMvQnV0dG9uL0JvdHRvbUJ1dHRvbi50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgUmVhY3QgZnJvbSBcInJlYWN0XCI7XG5pbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIjtcblxuaW1wb3J0IExvYWRpbmdCdXR0b24sIHtcbiAgUHJvcHMgYXMgTG9hZGluZ0J1dHRvblByb3BzLFxufSBmcm9tIFwic3JjL2NvbXBvbmVudHMvQnV0dG9uL0xvYWRpbmdCdXR0b25cIjtcblxuZXhwb3J0IHR5cGUgUHJvcHMgPSB7XG4gIGNsYXNzTmFtZT86IHN0cmluZztcbiAgTGVmdENvbXBvbmVudD86IFJlYWN0LlJlYWN0Tm9kZTtcbiAgYnV0dG9uTmFtZTogc3RyaW5nO1xuICBidXR0b25Qcm9wczogT21pdDxMb2FkaW5nQnV0dG9uUHJvcHMsIFwiY2hpbGRyZW5cIj47XG59O1xuXG5jb25zdCBDb250YWluZXIgPSBzdHlsZWQuZGl2YFxuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4OiAxO1xuICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWFyb3VuZDtcbmA7XG5cbmNvbnN0IExlZnQgPSBzdHlsZWQuZGl2YFxuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4OiAxO1xuICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuYDtcblxuY29uc3QgQm90dG9tQnV0dG9uID0gKHByb3BzOiBQcm9wcykgPT4ge1xuICBjb25zdCB7IGNsYXNzTmFtZSwgTGVmdENvbXBvbmVudCwgYnV0dG9uTmFtZSwgYnV0dG9uUHJvcHMgfSA9IHByb3BzO1xuXG4gIHJldHVybiAoXG4gICAgPENvbnRhaW5lciBjbGFzc05hbWU9e2NsYXNzTmFtZX0+XG4gICAgICA8TGVmdD57TGVmdENvbXBvbmVudH08L0xlZnQ+XG4gICAgICA8TG9hZGluZ0J1dHRvbiBwcm9ncmVzc1Byb3BzPXt7IHNpemU6IFwic21hbGxcIiB9fSB7Li4uYnV0dG9uUHJvcHN9PlxuICAgICAgICB7YnV0dG9uTmFtZX1cbiAgICAgIDwvTG9hZGluZ0J1dHRvbj5cbiAgICA8L0NvbnRhaW5lcj5cbiAgKTtcbn07XG5cbmV4cG9ydCBkZWZhdWx0IEJvdHRvbUJ1dHRvbjtcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

var Left = _styled("div", process.env.NODE_ENV === "production" ? {
  target: "e1kmktbf0"
} : {
  target: "e1kmktbf0",
  label: "Left"
})(process.env.NODE_ENV === "production" ? {
  name: "1lcbhv2",
  styles: "display:flex;flex:1;flex-direction:row;align-items:center"
} : {
  name: "1lcbhv2",
  styles: "display:flex;flex:1;flex-direction:row;align-items:center",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9wYWNrYWdlcy9oZW5lc2lzLXdhbGxldC1kYXNoYm9hcmQvc3JjL2NvbXBvbmVudHMvQnV0dG9uL0JvdHRvbUJ1dHRvbi50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBcUJ1QiIsImZpbGUiOiIvcGFja2FnZXMvaGVuZXNpcy13YWxsZXQtZGFzaGJvYXJkL3NyYy9jb21wb25lbnRzL0J1dHRvbi9Cb3R0b21CdXR0b24udHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IFJlYWN0IGZyb20gXCJyZWFjdFwiO1xuaW1wb3J0IHN0eWxlZCBmcm9tIFwiQGVtb3Rpb24vc3R5bGVkXCI7XG5cbmltcG9ydCBMb2FkaW5nQnV0dG9uLCB7XG4gIFByb3BzIGFzIExvYWRpbmdCdXR0b25Qcm9wcyxcbn0gZnJvbSBcInNyYy9jb21wb25lbnRzL0J1dHRvbi9Mb2FkaW5nQnV0dG9uXCI7XG5cbmV4cG9ydCB0eXBlIFByb3BzID0ge1xuICBjbGFzc05hbWU/OiBzdHJpbmc7XG4gIExlZnRDb21wb25lbnQ/OiBSZWFjdC5SZWFjdE5vZGU7XG4gIGJ1dHRvbk5hbWU6IHN0cmluZztcbiAgYnV0dG9uUHJvcHM6IE9taXQ8TG9hZGluZ0J1dHRvblByb3BzLCBcImNoaWxkcmVuXCI+O1xufTtcblxuY29uc3QgQ29udGFpbmVyID0gc3R5bGVkLmRpdmBcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleDogMTtcbiAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAganVzdGlmeS1jb250ZW50OiBzcGFjZS1hcm91bmQ7XG5gO1xuXG5jb25zdCBMZWZ0ID0gc3R5bGVkLmRpdmBcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleDogMTtcbiAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbmA7XG5cbmNvbnN0IEJvdHRvbUJ1dHRvbiA9IChwcm9wczogUHJvcHMpID0+IHtcbiAgY29uc3QgeyBjbGFzc05hbWUsIExlZnRDb21wb25lbnQsIGJ1dHRvbk5hbWUsIGJ1dHRvblByb3BzIH0gPSBwcm9wcztcblxuICByZXR1cm4gKFxuICAgIDxDb250YWluZXIgY2xhc3NOYW1lPXtjbGFzc05hbWV9PlxuICAgICAgPExlZnQ+e0xlZnRDb21wb25lbnR9PC9MZWZ0PlxuICAgICAgPExvYWRpbmdCdXR0b24gcHJvZ3Jlc3NQcm9wcz17eyBzaXplOiBcInNtYWxsXCIgfX0gey4uLmJ1dHRvblByb3BzfT5cbiAgICAgICAge2J1dHRvbk5hbWV9XG4gICAgICA8L0xvYWRpbmdCdXR0b24+XG4gICAgPC9Db250YWluZXI+XG4gICk7XG59O1xuXG5leHBvcnQgZGVmYXVsdCBCb3R0b21CdXR0b247XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

var BottomButton = function BottomButton(props) {
  var className = props.className,
      LeftComponent = props.LeftComponent,
      buttonName = props.buttonName,
      buttonProps = props.buttonProps;
  return __jsx(Container, {
    className: className
  }, __jsx(Left, null, LeftComponent), __jsx(LoadingButton, _extends({
    progressProps: {
      size: "small"
    }
  }, buttonProps), buttonName));
};

export default BottomButton;