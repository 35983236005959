import _defineProperty from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
import { WalletType } from "@haechi-labs/henesis-wallet-core/lib/__generate__/eth";
import { WalletStatus as HWalletStatus } from "@haechi-labs/henesis-wallet-core/lib/wallet";
export var EMPTY_WALLET_ID = "empty";
export var WalletStatus;

(function (WalletStatus) {
  WalletStatus["ACTIVE"] = "ACTIVE";
  WalletStatus["CREATING"] = "CREATING";
  WalletStatus["FAILED"] = "FAILED";
  WalletStatus["INACTIVE"] = "INACTIVE";
  WalletStatus["EXPECTED_INACTIVE"] = "EXPECTED_INACTIVE";
})(WalletStatus || (WalletStatus = {}));

export var transformTransferAddressUserItem = function transformTransferAddressUserItem(address, wallet) {
  if (!wallet) {
    return {
      userType: "address",
      address: address
    };
  }

  if (wallet.type === WalletType.USERWALLET) {
    var _masterWalletId = wallet.masterWalletId,
        _masterWalletName = wallet.masterWalletName,
        userWalletId = wallet.userWalletId,
        userWalletName = wallet.userWalletName;
    return {
      userType: "user_wallet",
      address: address,
      masterWallet: {
        id: _masterWalletId,
        name: _masterWalletName
      },
      userWallet: {
        id: userWalletId !== null && userWalletId !== void 0 ? userWalletId : "",
        name: userWalletName !== null && userWalletName !== void 0 ? userWalletName : "",
        masterWalletId: _masterWalletId
      }
    };
  }

  var masterWalletId = wallet.masterWalletId,
      masterWalletName = wallet.masterWalletName;
  return {
    userType: "master_wallet",
    address: address,
    masterWallet: {
      id: masterWalletId,
      name: masterWalletName
    }
  };
};
export var transformWalletStatus = function transformWalletStatus(hWalletStatus) {
  var _map;

  var map = (_map = {}, _defineProperty(_map, HWalletStatus.ACTIVE, WalletStatus.ACTIVE), _defineProperty(_map, HWalletStatus.CREATING, WalletStatus.CREATING), _defineProperty(_map, HWalletStatus.FAILED, WalletStatus.FAILED), _defineProperty(_map, HWalletStatus.INACTIVE, WalletStatus.INACTIVE), _map);
  return map[hWalletStatus];
};