export var WALLET_VERSION_V5 = "v5";
export var WALLET_VERSION_V4 = "v4";
export var isLessThanWalletV5 = function isLessThanWalletV5(version) {
  var verisonNumber = parseInt(version.substr(1));
  var versionV5Number = parseInt(WALLET_VERSION_V5.substr(1));
  return verisonNumber < versionV5Number;
};
export var isLessThanWalletV4 = function isLessThanWalletV4(version) {
  var verisonNumber = parseInt(version.substr(1));
  var versionV4Number = parseInt(WALLET_VERSION_V4.substr(1));
  return verisonNumber < versionV4Number;
};