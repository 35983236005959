import _defineProperty from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
import _map from "lodash/map";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { TransferStatus } from "@haechi-labs/henesis-wallet-core/lib/btc/transfers";
import { TransferStatus as LitecoinTransferStatus } from "@haechi-labs/henesis-wallet-core/lib/ltc/transfers";
import { TransferStatus as BitcoinCashTransferStatus } from "@haechi-labs/henesis-wallet-core/lib/bch/transfers";
import { TransferStatus as FilTransferStatus } from "@haechi-labs/henesis-wallet-core/lib/__generate__/fil/api";
import { EventStatus, TransactionStatus } from "@haechi-labs/henesis-wallet-core/lib/__generate__/eth";
import { BlockchainType } from "@haechi-labs/henesis-wallet-core/lib/blockchain";
import { translate } from "../locales";
import { t } from "../libs/i18n";
export var EventStatusType;

(function (EventStatusType) {
  EventStatusType["ALL"] = "ALL";
  EventStatusType["PENDINGAPPROVAL"] = "PENDING_APPROVAL";
  EventStatusType["REJECTED"] = "REJECTED";
  EventStatusType["REQUESTED"] = "REQUESTED";
  EventStatusType["PENDING"] = "PENDING";
  EventStatusType["FAILED"] = "FAILED";
  EventStatusType["MINED"] = "MINED";
  EventStatusType["CONFIRMED"] = "CONFIRMED";
  EventStatusType["REPLACED"] = "REPLACED";
  EventStatusType["REVERTED"] = "REVERTED";
  EventStatusType["INTERNALREVERTED"] = "INTERNAL_REVERTED";
})(EventStatusType || (EventStatusType = {}));

var bitcoinEventStatusTypes = [EventStatusType.ALL, EventStatusType.PENDINGAPPROVAL, EventStatusType.REJECTED, EventStatusType.PENDING, EventStatusType.MINED, EventStatusType.CONFIRMED];
var bitcoinCashEventStatusTypes = [EventStatusType.ALL, EventStatusType.PENDINGAPPROVAL, EventStatusType.REJECTED, EventStatusType.PENDING, EventStatusType.MINED, EventStatusType.CONFIRMED];
var litecoinEventStatusTypes = [EventStatusType.ALL, EventStatusType.PENDINGAPPROVAL, EventStatusType.REJECTED, EventStatusType.PENDING, EventStatusType.MINED, EventStatusType.CONFIRMED];
var ethereumEventStatusTypes = [EventStatusType.ALL, EventStatusType.PENDINGAPPROVAL, EventStatusType.REJECTED, EventStatusType.REQUESTED, EventStatusType.FAILED, EventStatusType.PENDING, EventStatusType.MINED, EventStatusType.CONFIRMED, EventStatusType.REVERTED, EventStatusType.INTERNALREVERTED, EventStatusType.REPLACED];
var klaytnEventStatusTypes = [EventStatusType.ALL, EventStatusType.PENDINGAPPROVAL, EventStatusType.REJECTED, EventStatusType.REQUESTED, EventStatusType.FAILED, EventStatusType.PENDING, EventStatusType.CONFIRMED, EventStatusType.REVERTED, EventStatusType.INTERNALREVERTED];
var filecoinEventStatusTypes = [EventStatusType.ALL, EventStatusType.REQUESTED, EventStatusType.FAILED, EventStatusType.PENDING, EventStatusType.MINED, EventStatusType.CONFIRMED, EventStatusType.REVERTED];
var bscEventStatusTypes = [EventStatusType.ALL, EventStatusType.PENDINGAPPROVAL, EventStatusType.REJECTED, EventStatusType.REQUESTED, EventStatusType.FAILED, EventStatusType.PENDING, EventStatusType.MINED, EventStatusType.CONFIRMED, EventStatusType.REVERTED, EventStatusType.INTERNALREVERTED, EventStatusType.REPLACED];
var polygonEventStatusTypes = [EventStatusType.ALL, EventStatusType.PENDINGAPPROVAL, EventStatusType.REJECTED, EventStatusType.REQUESTED, EventStatusType.FAILED, EventStatusType.PENDING, EventStatusType.MINED, EventStatusType.CONFIRMED, EventStatusType.REVERTED, EventStatusType.INTERNALREVERTED, EventStatusType.REPLACED];
export var getEventStatusTypes = function getEventStatusTypes(blockchain) {
  var _byStatus, _byBlockchain;

  var byStatus = (_byStatus = {}, _defineProperty(_byStatus, EventStatusType.ALL, {
    label: translate(["eventStatus", "all"], t),
    statusName: ""
  }), _defineProperty(_byStatus, EventStatusType.PENDINGAPPROVAL, {
    label: translate(["eventStatus", "pendingApproval"], t),
    statusName: "Pending Approval"
  }), _defineProperty(_byStatus, EventStatusType.REJECTED, {
    label: translate(["eventStatus", "reject"], t),
    statusName: "Rejected"
  }), _defineProperty(_byStatus, EventStatusType.REQUESTED, {
    label: translate(["eventStatus", "requested"], t),
    statusName: "Requested"
  }), _defineProperty(_byStatus, EventStatusType.FAILED, {
    label: translate(["eventStatus", "failed"], t),
    statusName: "Failed"
  }), _defineProperty(_byStatus, EventStatusType.PENDING, {
    label: translate(["eventStatus", "pending"], t),
    statusName: "Pending"
  }), _defineProperty(_byStatus, EventStatusType.MINED, {
    label: translate(["eventStatus", "mined"], t),
    statusName: "Mined"
  }), _defineProperty(_byStatus, EventStatusType.CONFIRMED, {
    label: translate(["eventStatus", "confirmed"], t),
    statusName: "Confirmed"
  }), _defineProperty(_byStatus, EventStatusType.REVERTED, {
    label: translate(["eventStatus", "reverted"], t),
    statusName: "Reverted"
  }), _defineProperty(_byStatus, EventStatusType.REPLACED, {
    label: translate(["eventStatus", "replaced"], t),
    statusName: "Replaced"
  }), _defineProperty(_byStatus, EventStatusType.INTERNALREVERTED, {
    label: translate(["eventStatus", "internalReverted"], t),
    statusName: "Internal Reverted"
  }), _byStatus);
  var byBlockchain = (_byBlockchain = {}, _defineProperty(_byBlockchain, BlockchainType.ETHEREUM, _map(ethereumEventStatusTypes, function (status) {
    return _objectSpread(_objectSpread({}, byStatus[status]), {}, {
      status: status
    });
  })), _defineProperty(_byBlockchain, BlockchainType.KLAYTN, _map(klaytnEventStatusTypes, function (status) {
    return _objectSpread(_objectSpread({}, byStatus[status]), {}, {
      status: status
    });
  })), _defineProperty(_byBlockchain, BlockchainType.BINANCE_SMART_CHAIN, _map(bscEventStatusTypes, function (status) {
    return _objectSpread(_objectSpread({}, byStatus[status]), {}, {
      status: status
    });
  })), _defineProperty(_byBlockchain, BlockchainType.POLYGON, _map(polygonEventStatusTypes, function (status) {
    return _objectSpread(_objectSpread({}, byStatus[status]), {}, {
      status: status
    });
  })), _defineProperty(_byBlockchain, BlockchainType.FILECOIN, _map(filecoinEventStatusTypes, function (status) {
    return _objectSpread(_objectSpread({}, byStatus[status]), {}, {
      status: status
    });
  })), _defineProperty(_byBlockchain, BlockchainType.BITCOIN, _map(bitcoinEventStatusTypes, function (status) {
    return _objectSpread(_objectSpread({}, byStatus[status]), {}, {
      status: status
    });
  })), _defineProperty(_byBlockchain, BlockchainType.BITCOIN_CASH, _map(bitcoinCashEventStatusTypes, function (status) {
    return _objectSpread(_objectSpread({}, byStatus[status]), {}, {
      status: status
    });
  })), _defineProperty(_byBlockchain, BlockchainType.LITECOIN, _map(litecoinEventStatusTypes, function (status) {
    return _objectSpread(_objectSpread({}, byStatus[status]), {}, {
      status: status
    });
  })), _byBlockchain);
  return byBlockchain[blockchain];
};
export var getFlushTransactionEventStatusTypes = function getFlushTransactionEventStatusTypes(blockchain) {
  var excludeEventStatuses = [EventStatusType.PENDINGAPPROVAL, EventStatusType.REJECTED];
  return getEventStatusTypes(blockchain).filter(function (item) {
    return !excludeEventStatuses.some(function (excludeStatus) {
      return excludeStatus === item.status;
    });
  });
};
export var getTransferEventStatusTypes = function getTransferEventStatusTypes(blockchain) {
  var excludeEventStatuses = [EventStatusType.INTERNALREVERTED];
  return getEventStatusTypes(blockchain).filter(function (item) {
    return !excludeEventStatuses.some(function (excludeStatus) {
      return excludeStatus === item.status;
    });
  });
};
export var transformEthereumAndKlaytnEventStatus = function transformEthereumAndKlaytnEventStatus(eventStatus) {
  var _byStatus2;

  var byStatus = (_byStatus2 = {}, _defineProperty(_byStatus2, EventStatusType.ALL, undefined), _defineProperty(_byStatus2, EventStatusType.PENDINGAPPROVAL, EventStatus.PENDINGAPPROVAL), _defineProperty(_byStatus2, EventStatusType.REJECTED, EventStatus.REJECTED), _defineProperty(_byStatus2, EventStatusType.REQUESTED, EventStatus.REQUESTED), _defineProperty(_byStatus2, EventStatusType.PENDING, EventStatus.PENDING), _defineProperty(_byStatus2, EventStatusType.FAILED, EventStatus.FAILED), _defineProperty(_byStatus2, EventStatusType.MINED, EventStatus.MINED), _defineProperty(_byStatus2, EventStatusType.CONFIRMED, EventStatus.CONFIRMED), _defineProperty(_byStatus2, EventStatusType.REPLACED, EventStatus.REPLACED), _defineProperty(_byStatus2, EventStatusType.REVERTED, EventStatusType.REVERTED), _defineProperty(_byStatus2, EventStatusType.INTERNALREVERTED, EventStatusType.INTERNALREVERTED), _byStatus2);
  return byStatus[eventStatus];
};
export var transformEthereumAndKlaytnTransactionStatus = function transformEthereumAndKlaytnTransactionStatus(eventStatus) {
  var _byStatus3;

  var byStatus = (_byStatus3 = {}, _defineProperty(_byStatus3, EventStatusType.ALL, undefined), _defineProperty(_byStatus3, EventStatusType.PENDINGAPPROVAL, TransactionStatus.PENDINGAPPROVAL), _defineProperty(_byStatus3, EventStatusType.REJECTED, TransactionStatus.REJECTED), _defineProperty(_byStatus3, EventStatusType.REQUESTED, TransactionStatus.REQUESTED), _defineProperty(_byStatus3, EventStatusType.PENDING, TransactionStatus.PENDING), _defineProperty(_byStatus3, EventStatusType.FAILED, TransactionStatus.FAILED), _defineProperty(_byStatus3, EventStatusType.MINED, TransactionStatus.MINED), _defineProperty(_byStatus3, EventStatusType.CONFIRMED, TransactionStatus.CONFIRMED), _defineProperty(_byStatus3, EventStatusType.REPLACED, TransactionStatus.REPLACED), _defineProperty(_byStatus3, EventStatusType.REVERTED, TransactionStatus.REVERTED), _defineProperty(_byStatus3, EventStatusType.INTERNALREVERTED, TransactionStatus.INTERNALREVERTED), _byStatus3);
  return byStatus[eventStatus];
};
export var transformBitcoinEventStatus = function transformBitcoinEventStatus(eventStatus) {
  var _byStatus4;

  var byStatus = (_byStatus4 = {}, _defineProperty(_byStatus4, EventStatusType.ALL, undefined), _defineProperty(_byStatus4, EventStatusType.PENDINGAPPROVAL, TransferStatus.PENDINGAPPROVAL), _defineProperty(_byStatus4, EventStatusType.REJECTED, TransferStatus.REJECTED), _defineProperty(_byStatus4, EventStatusType.REQUESTED, TransferStatus.REQUESTED), _defineProperty(_byStatus4, EventStatusType.PENDING, TransferStatus.PENDING), _defineProperty(_byStatus4, EventStatusType.FAILED, undefined), _defineProperty(_byStatus4, EventStatusType.MINED, TransferStatus.MINED), _defineProperty(_byStatus4, EventStatusType.CONFIRMED, TransferStatus.CONFIRMED), _defineProperty(_byStatus4, EventStatusType.REPLACED, undefined), _defineProperty(_byStatus4, EventStatusType.REVERTED, undefined), _defineProperty(_byStatus4, EventStatusType.INTERNALREVERTED, undefined), _byStatus4);
  return byStatus[eventStatus];
};
export var transformBitcoinCashEventStatus = function transformBitcoinCashEventStatus(eventStatus) {
  var _byStatus5;

  var byStatus = (_byStatus5 = {}, _defineProperty(_byStatus5, EventStatusType.ALL, undefined), _defineProperty(_byStatus5, EventStatusType.PENDINGAPPROVAL, BitcoinCashTransferStatus.PENDINGAPPROVAL), _defineProperty(_byStatus5, EventStatusType.REJECTED, BitcoinCashTransferStatus.REJECTED), _defineProperty(_byStatus5, EventStatusType.REQUESTED, BitcoinCashTransferStatus.REQUESTED), _defineProperty(_byStatus5, EventStatusType.PENDING, BitcoinCashTransferStatus.PENDING), _defineProperty(_byStatus5, EventStatusType.FAILED, undefined), _defineProperty(_byStatus5, EventStatusType.MINED, BitcoinCashTransferStatus.MINED), _defineProperty(_byStatus5, EventStatusType.CONFIRMED, BitcoinCashTransferStatus.CONFIRMED), _defineProperty(_byStatus5, EventStatusType.REPLACED, undefined), _defineProperty(_byStatus5, EventStatusType.REVERTED, undefined), _defineProperty(_byStatus5, EventStatusType.INTERNALREVERTED, undefined), _byStatus5);
  return byStatus[eventStatus];
};
export var transformLitecoinEventStatus = function transformLitecoinEventStatus(eventStatus) {
  var _byStatus6;

  var byStatus = (_byStatus6 = {}, _defineProperty(_byStatus6, EventStatusType.ALL, undefined), _defineProperty(_byStatus6, EventStatusType.PENDINGAPPROVAL, LitecoinTransferStatus.PENDINGAPPROVAL), _defineProperty(_byStatus6, EventStatusType.REJECTED, LitecoinTransferStatus.REJECTED), _defineProperty(_byStatus6, EventStatusType.REQUESTED, LitecoinTransferStatus.REQUESTED), _defineProperty(_byStatus6, EventStatusType.PENDING, LitecoinTransferStatus.PENDING), _defineProperty(_byStatus6, EventStatusType.FAILED, undefined), _defineProperty(_byStatus6, EventStatusType.MINED, LitecoinTransferStatus.MINED), _defineProperty(_byStatus6, EventStatusType.CONFIRMED, LitecoinTransferStatus.CONFIRMED), _defineProperty(_byStatus6, EventStatusType.REPLACED, undefined), _defineProperty(_byStatus6, EventStatusType.REVERTED, undefined), _defineProperty(_byStatus6, EventStatusType.INTERNALREVERTED, undefined), _byStatus6);
  return byStatus[eventStatus];
};
export var transformFilecoinEventStatus = function transformFilecoinEventStatus(eventStatus) {
  var _byStatus7;

  var byStatus = (_byStatus7 = {}, _defineProperty(_byStatus7, EventStatusType.ALL, undefined), _defineProperty(_byStatus7, EventStatusType.PENDINGAPPROVAL, undefined), _defineProperty(_byStatus7, EventStatusType.REJECTED, undefined), _defineProperty(_byStatus7, EventStatusType.REQUESTED, FilTransferStatus.REQUESTED), _defineProperty(_byStatus7, EventStatusType.PENDING, FilTransferStatus.PENDING), _defineProperty(_byStatus7, EventStatusType.FAILED, FilTransferStatus.FAILED), _defineProperty(_byStatus7, EventStatusType.MINED, FilTransferStatus.MINED), _defineProperty(_byStatus7, EventStatusType.CONFIRMED, FilTransferStatus.CONFIRMED), _defineProperty(_byStatus7, EventStatusType.REPLACED, undefined), _defineProperty(_byStatus7, EventStatusType.REVERTED, FilTransferStatus.REVERTED), _defineProperty(_byStatus7, EventStatusType.INTERNALREVERTED, undefined), _byStatus7);
  return byStatus[eventStatus];
};
export var transformEventStatus = function transformEventStatus(eventStatus) {
  var byStatus = {
    PENDING_APPROVAL: EventStatusType.PENDINGAPPROVAL,
    REJECTED: EventStatusType.REJECTED,
    REQUESTED: EventStatusType.REQUESTED,
    PENDING: EventStatusType.PENDING,
    FAILED: EventStatusType.FAILED,
    MINED: EventStatusType.MINED,
    CONFIRMED: EventStatusType.CONFIRMED,
    REPLACED: EventStatusType.REPLACED,
    REVERTED: EventStatusType.REVERTED,
    INTERNAL_REVERTED: EventStatusType.INTERNALREVERTED
  };
  return byStatus[eventStatus];
};