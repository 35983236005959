import { getDashboardWalletPath, getDashboardWalletUrlPath } from "../pages/dashboard/wallet/[blockchain]/[id]";
import { getDashboardWalletPath as getDashboardWalletV2Path, getDashboardWalletUrlPath as getDashboardWalletV2UrlPath } from "../pages/dashboard/wallet/v2/[blockchain]/[id]";
import { getDashboardUserWalletUrlPath, getDashboardUserWalletPath } from "../pages/dashboard/wallet/[blockchain]/[id]/user/[userWalletId]";
import { getDashboardDepositAddressUrlPath, getDashboardDepositAddressPath } from "../pages/dashboard/wallet/v2/[blockchain]/[id]/deposit-address/[depositAddressId]";
import { BlockchainType } from "@haechi-labs/henesis-wallet-core/lib/blockchain";
export var isDashboardPath = function isDashboardPath(asPath) {
  if (asPath.startsWith("/dashboard")) {
    return true;
  }

  if (["/401", "/404"].some(function (path) {
    return asPath.startsWith(path);
  })) {
    return true;
  }

  return false;
};
export var getDashboardWallet = function getDashboardWallet(params) {
  var isNewWallet = params.isNewWallet,
      blockchain = params.blockchain;
  var _params$queryParams = params.queryParams,
      id = _params$queryParams.id,
      tab = _params$queryParams.tab;

  if (isNewWallet) {
    return {
      dashboardWalletPath: getDashboardWalletV2Path({
        id: id,
        blockchain: blockchain,
        tab: tab
      }),
      dashboardWalletUrlPath: getDashboardWalletV2UrlPath()
    };
  }

  return {
    dashboardWalletPath: getDashboardWalletPath({
      id: id,
      blockchain: blockchain,
      tab: tab
    }),
    dashboardWalletUrlPath: getDashboardWalletUrlPath()
  };
};
export var getDashboardSubWallet = function getDashboardSubWallet(params) {
  var isNewWallet = params.isNewWallet,
      blockchain = params.blockchain;
  var _params$queryParams2 = params.queryParams,
      id = _params$queryParams2.id,
      masterWalletTab = _params$queryParams2.masterWalletTab,
      subWalletId = _params$queryParams2.subWalletId;

  if (isNewWallet || [BlockchainType.FILECOIN, BlockchainType.BITCOIN].some(function (item) {
    return item === blockchain;
  })) {
    return {
      dashboardSubWalletPath: getDashboardDepositAddressPath({
        id: id,
        masterWalletTab: masterWalletTab,
        depositAddressId: subWalletId,
        blockchain: blockchain
      }),
      dashboardSubWalletUrlPath: getDashboardDepositAddressUrlPath()
    };
  }

  return {
    dashboardSubWalletPath: getDashboardUserWalletPath({
      id: id,
      masterWalletTab: masterWalletTab,
      userWalletId: subWalletId,
      blockchain: blockchain
    }),
    dashboardSubWalletUrlPath: getDashboardUserWalletUrlPath()
  };
};