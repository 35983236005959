import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import * as React from "react";

function SvgComponent(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none"
  }, props), __jsx("path", {
    d: "M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z",
    fill: "#E61244"
  }), __jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M11.7676 4C13.3883 4.05718 14.9928 4.63498 16.3097 5.73595L7.94147 14.1042L11.7676 4ZM6.69635 17.2594L11.5031 12.4527L16.309 17.2585C13.5326 19.58 9.47268 19.5808 6.69635 17.2594ZM17.2636 6.69099L12.4568 11.4977L17.2627 16.3036C19.5841 13.5273 19.585 9.46732 17.2636 6.69099ZM4.0148 11.0255L9.88666 5.15369L5.68845 16.2402C4.4569 14.7347 3.89959 12.8631 4.0148 11.0255Z",
    fill: "white"
  }));
}

export default SvgComponent;