import { useEffect, useMemo } from "react";
import { getRootStore } from "../stores/Store";
import { setupReactotron } from "../ReactotronConfig";
import { isDashboardPath } from "../utils/navigator";

function useStore(router, initialState) {
  var store = useMemo(function () {
    return getRootStore(false, initialState);
  }, [initialState]);
  useEffect(function () {
    setupReactotron(store);

    if (isDashboardPath(router.asPath)) {
      store.initializeClient();
    }

    store.remoteConfigStore.initialize();
    store.authStore.initializeClientLanguage(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return store;
}

export default useStore;