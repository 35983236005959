import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
import _styled from "@emotion/styled/base";
import _partial from "lodash/partial";
import _map from "lodash/map";
var __jsx = React.createElement;

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import React from "react";
import TabItem from "./TabItem";

var Container = _styled("div", process.env.NODE_ENV === "production" ? {
  target: "e18e19gi0"
} : {
  target: "e18e19gi0",
  label: "Container"
})(process.env.NODE_ENV === "production" ? {
  name: "ym525",
  styles: "width:100%;display:flex;flex-direction:row;align-items:center"
} : {
  name: "ym525",
  styles: "width:100%;display:flex;flex-direction:row;align-items:center",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9wYWNrYWdlcy9oZW5lc2lzLXdhbGxldC1kYXNoYm9hcmQvc3JjL2NvbXBvbmVudHMvVGFiL1RhYi50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBZTRCIiwiZmlsZSI6Ii9wYWNrYWdlcy9oZW5lc2lzLXdhbGxldC1kYXNoYm9hcmQvc3JjL2NvbXBvbmVudHMvVGFiL1RhYi50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgXyBmcm9tIFwibG9kYXNoXCI7XG5pbXBvcnQgUmVhY3QgZnJvbSBcInJlYWN0XCI7XG5pbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIjtcblxuaW1wb3J0IFRhYkl0ZW0sIHsgUHJvcHMgYXMgVGFiSXRlbVByb3BzIH0gZnJvbSBcInNyYy9jb21wb25lbnRzL1RhYi9UYWJJdGVtXCI7XG5cbnR5cGUgUHJvcHMgPSB7XG4gIGNsYXNzTmFtZT86IHN0cmluZztcbiAgc2VsZWN0ZWRJbmRleDogbnVtYmVyO1xuICBkYXRhOiBBcnJheTxcbiAgICBPbWl0PFRhYkl0ZW1Qcm9wcywgXCJhY3RpdmVcIiB8IFwib25DbGlja1wiPiAmIHsgZGlzYWJsZWQ/OiBib29sZWFuIH1cbiAgPjtcbiAgb25TZWxlY3RlZDogKGluZGV4OiBudW1iZXIpID0+IHZvaWQ7XG59O1xuXG5jb25zdCBDb250YWluZXIgPSBzdHlsZWQuZGl2YFxuICB3aWR0aDogMTAwJTtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbmA7XG5cbmNvbnN0IFRhYiA9IChwcm9wczogUHJvcHMpID0+IHtcbiAgY29uc3QgeyBjbGFzc05hbWUsIGRhdGEsIG9uU2VsZWN0ZWQsIHNlbGVjdGVkSW5kZXggfSA9IHByb3BzO1xuICByZXR1cm4gKFxuICAgIDxDb250YWluZXIgY2xhc3NOYW1lPXtjbGFzc05hbWV9PlxuICAgICAge18ubWFwKGRhdGEsIChpdGVtLCBpbmRleCkgPT4ge1xuICAgICAgICBjb25zdCB7IGRpc2FibGVkIH0gPSBpdGVtO1xuICAgICAgICByZXR1cm4gKFxuICAgICAgICAgIDxUYWJJdGVtXG4gICAgICAgICAgICBrZXk9e2luZGV4fVxuICAgICAgICAgICAgZGlzYWJsZWQ9e2Rpc2FibGVkfVxuICAgICAgICAgICAgYWN0aXZlPXtpbmRleCA9PT0gc2VsZWN0ZWRJbmRleH1cbiAgICAgICAgICAgIG9uQ2xpY2s9e18ucGFydGlhbChvblNlbGVjdGVkLCBpbmRleCl9XG4gICAgICAgICAgICB7Li4uaXRlbX1cbiAgICAgICAgICAvPlxuICAgICAgICApO1xuICAgICAgfSl9XG4gICAgPC9Db250YWluZXI+XG4gICk7XG59O1xuXG5leHBvcnQgZGVmYXVsdCBUYWI7XG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

var Tab = function Tab(props) {
  var className = props.className,
      data = props.data,
      onSelected = props.onSelected,
      selectedIndex = props.selectedIndex;
  return __jsx(Container, {
    className: className
  }, _map(data, function (item, index) {
    var disabled = item.disabled;
    return __jsx(TabItem, _extends({
      key: index,
      disabled: disabled,
      active: index === selectedIndex,
      onClick: _partial(onSelected, index)
    }, item));
  }));
};

export default Tab;