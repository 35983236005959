import Btc from "./icon/coin/btc";
import BtcBox from "./icon/coin/btc_box";
import Eth from "./icon/coin/eth";
import EthBox from "./icon/coin/eth_box";
import Klaytn from "./icon/coin/klaytn";
import KlaytnBox from "./icon/coin/klaytn_box";
import Filecoin from "./icon/coin/filecoin";
import FilecoinBox from "./icon/coin/filecoin_box";
import LitecoinBox from "./icon/coin/litecoin_box";
import BscBox from "./icon/coin/bcs_box";
import BchBox from "./icon/coin/bch_box";
import MaticBox from "./icon/coin/matic_box";
import FilterListSelected from "./icon/filter_list_selected";
import GasStation from "./icon/gas_station";
import PaginationFirst from "./icon/pagination_first";
import PaginationLast from "./icon/pagination_last";
import User from "./icon/user";
import AndroidCustom from "./icon/android_custom";
import IOS from "./icon/ios";
import SubMenu from "./icon/sub_menu";
import WarningRounded from "./icon/warning_rounded";
import GasFee from "./icon/gas_fee";
import LogoDark from "./logo_dark";
import LogoDarkTest from "./logo_dark_test";
import WalletLogo from "./wallet_logo_dark";
import WalletLogoTest from "./wallet_logo_dark_test";
import OtpIcon from "./otp";
import KeyfilePreview from "./keyfile_preview";
var images = {
  icons: {
    btc: Btc,
    btc_box: BtcBox,
    eth: Eth,
    eth_box: EthBox,
    filecoin: Filecoin,
    filecoin_box: FilecoinBox,
    litecoin_box: LitecoinBox,
    bsc_box: BscBox,
    bch_box: BchBox,
    klaytn: Klaytn,
    klaytn_box: KlaytnBox,
    filterListSelected: FilterListSelected,
    gasStation: GasStation,
    paginationFirst: PaginationFirst,
    paginationLast: PaginationLast,
    user: User,
    android_custom: AndroidCustom,
    ios: IOS,
    subMenu: SubMenu,
    warning_rounded: WarningRounded,
    gas_fee: GasFee,
    matic_box: MaticBox
  },
  walletLogo: WalletLogo,
  walletLogoTest: WalletLogoTest,
  logoDark: LogoDark,
  logoDarkTest: LogoDarkTest,
  keyfilePreview: KeyfilePreview,
  otpIcon: OtpIcon,
  bgPlaceholderNft: require("./bg_placeholder_nft.png"),
  btcWalletPolicyInfo: require("./btc_wallet_policy_info.png"),
  ethMasterwalletPolicyInfo: require("./eth_masterwallet_policy_info.png"),
  ethWalletPolicyInfo: require("./eth_wallet_policy_info.png"),
  ethUserwalletPolicyInfo: require("./eth_userwallet_policy_info.png"),
  klayMasterwalletPolicyInfo: require("./klay_masterwallet_policy_info.png"),
  klayUserwalletPolicyInfo: require("./klay_userwallet_policy_info.png"),
  hopTransactionInfo: require("./hop_transaction_info.png"),
  icSubmenu: require("./ic_submenu.png")
};
export default images;