import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from "react";

var SvgComponent = function SvgComponent(props) {
  return __jsx("svg", _extends({
    width: 11,
    height: 15,
    viewBox: "0 0 11 15",
    fill: "none"
  }, props), __jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M13.32 10.213l-.33 1.758 3.132.44-.22.824-3.077-.44c-.22.715-.33 1.484-.605 2.144-.275.769-.55 1.538-.879 2.253-.44.934-1.209 1.593-2.253 1.758-.604.11-1.264.055-1.758-.33-.165-.11-.33-.33-.33-.494 0-.22.11-.495.275-.605.11-.055.384 0 .55.055.164.165.329.385.439.605.33.44.77.494 1.209.165.494-.44.769-1.044.934-1.649.33-1.319.66-2.582.934-3.901v-.22l-2.912-.44.11-.824 3.022.44.384-1.704-3.132-.494.11-.88 3.242.44c.11-.33.165-.604.275-.88.275-.988.55-1.977 1.209-2.856.66-.88 1.429-1.43 2.583-1.374.494 0 .989.165 1.318.55.055.054.165.164.165.274 0 .22 0 .495-.165.66-.22.164-.494.11-.714-.11-.165-.165-.275-.33-.44-.495-.33-.44-.824-.495-1.209-.11a3.52 3.52 0 00-.714 1.044c-.385 1.154-.66 2.363-1.044 3.572l3.022.44-.22.824-2.912-.44z",
    fill: "#fff"
  }));
};

export default SvgComponent;