var __jsx = React.createElement;
import React from "react";
import Snackbar from "./Snackbar";
import MaterialIcon from "../Icon/MaterialIcon";
import { palette } from "../../themes/palette";

var CheckSnackbar = function CheckSnackbar(props) {
  var message = props.message,
      action = props.action;
  return __jsx(Snackbar, {
    IconComponent: __jsx(MaterialIcon, {
      name: "check_circle",
      fontSize: "small",
      color: palette.green.main
    }),
    message: message,
    action: action
  });
};

export default CheckSnackbar;