export function delay() {
  var ms = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 500;
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(undefined);
    }, ms);
  });
}
export function isJSON(str) {
  try {
    var obj = JSON.parse(str);

    if (obj && typeof obj === "object" && obj !== null) {
      return true;
    }
  } catch (err) {// NOTHING
  }

  return false;
}
export var getValue = function getValue(func, defaultValue) {
  try {
    return func();
  } catch (error) {
    return defaultValue;
  }
};
export var mergeRefs = function mergeRefs() {
  for (var _len = arguments.length, refs = new Array(_len), _key = 0; _key < _len; _key++) {
    refs[_key] = arguments[_key];
  }

  return function (ref) {
    refs.forEach(function (resolvableRef) {
      if (typeof resolvableRef === "function") {
        resolvableRef(ref);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        resolvableRef.current = ref;
      }
    });
  };
}; // eslint-disable-next-line @typescript-eslint/no-explicit-any

export function getEnumKeyByEnumValue(myEnum, enumValue) {
  var keys = Object.keys(myEnum).filter(function (x) {
    return myEnum[x] == enumValue;
  });
  return keys.length > 0 ? keys[0] : null;
}