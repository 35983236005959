import { types } from "mobx-state-tree";
var DialogStore = types.model("DialogStore", {
  dialog: types.frozen(null),
  payload: types.frozen(null)
}).views(function (self) {
  return {
    get retryWalletDialogParams() {
      return self.payload;
    },

    get gasFeeDepositDialogParams() {
      return self.payload;
    }

  };
}).actions(function (self) {
  var showRetryWalletDialog = function showRetryWalletDialog(payload) {
    showDialog("retryWallet", payload);
  };

  var showDialog = function showDialog(dialog, payload) {
    self.dialog = dialog;

    if (!payload) {
      return;
    }

    self.payload = payload;
  };

  var closeDialog = function closeDialog() {
    self.dialog = null;
    self.payload = null;
  };

  return {
    showDialog: showDialog,
    showRetryWalletDialog: showRetryWalletDialog,
    closeDialog: closeDialog
  };
});
export default DialogStore;