import _defineProperty from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
import _constant from "lodash/constant";
import _matches from "lodash/matches";
import _cond from "lodash/cond";
import { BlockchainType } from "@haechi-labs/henesis-wallet-core/lib/blockchain";
import { convertToNewAddress as convertToLtcNewAddress, convertToLegacyAddress as convertToLtcLegacyAddress, isLegacyAddress as isLtcLegacyAddress, isNewAddress as isLtcNewAddress } from "@haechi-labs/henesis-wallet-core/lib/ltc/utils";
import { convertToNewAddress as convertToBchNewAddress, convertToLegacyAddress as convertToBchLegacyAddress, isLegacyAddress as isBchLegacyAddress, isNewAddress as isBchNewAddress } from "@haechi-labs/henesis-wallet-core/lib/bch/utils";
import { isProduction } from "../libs/env";
import { TransferType } from "../interfaces/transfer";
export var hasLegacyAndNewAddressByBlockchain = function hasLegacyAndNewAddressByBlockchain(blockchain) {
  return [BlockchainType.LITECOIN, BlockchainType.BITCOIN_CASH].some(function (targetBlockchain) {
    return targetBlockchain === blockchain;
  });
};
export var getAddressPlaceholder = function getAddressPlaceholder(blockchain) {
  var _AddressPlaceholderBy;

  var AddressPlaceholderByBlockchain = (_AddressPlaceholderBy = {}, _defineProperty(_AddressPlaceholderBy, BlockchainType.ETHEREUM, "ex) 0x1f10ecBD971eaB345eA19e96dC237b1FBD63De96"), _defineProperty(_AddressPlaceholderBy, BlockchainType.KLAYTN, "ex) 0x1f10ecBD971eaB345eA19e96dC237b1FBD63De96"), _defineProperty(_AddressPlaceholderBy, BlockchainType.BINANCE_SMART_CHAIN, "ex) 0x1f10ecBD971eaB345eA19e96dC237b1FBD63De96"), _defineProperty(_AddressPlaceholderBy, BlockchainType.POLYGON, "ex) 0x1f10ecBD971eaB345eA19e96dC237b1FBD63De96"), _defineProperty(_AddressPlaceholderBy, BlockchainType.FILECOIN, isProduction ? "ex) f2r5e5eevpoo3efcfnjcqvm3isg5fyj3aj5picqaq" : "ex) t2gesfevex7m4sge6hqvnjuogthc3vnjlmhasg7sq"), _defineProperty(_AddressPlaceholderBy, BlockchainType.BITCOIN, isProduction ? "ex) 35bzuR9XrRxu7Hj2KYffyEGorvV8tTUQTn" : "ex) 2NGCFYqUC1WaZEyL1SnQ9WJuiaJQJji4ii8"), _defineProperty(_AddressPlaceholderBy, BlockchainType.BITCOIN_CASH, isProduction ? "ex) bitcoincash:qz9ew4c9l7rt0rqv6ced08k0wts6vxwuqvvuuv2szl" : "ex) bchtest:pq3r4uehturg9ntv35xjezl773pcxwz9vgaqy2avn6"), _defineProperty(_AddressPlaceholderBy, BlockchainType.LITECOIN, isProduction ? "ex) 35bzuR9XrRxu7Hj2KYffyEGorvV8tTUQTn" : "ex) 2NGCFYqUC1WaZEyL1SnQ9WJuiaJQJji4ii8"), _AddressPlaceholderBy);
  return AddressPlaceholderByBlockchain[blockchain];
};
export var getAddressMaxLength = function getAddressMaxLength(blockchain) {
  var _byBlockchain;

  var byBlockchain = (_byBlockchain = {}, _defineProperty(_byBlockchain, BlockchainType.ETHEREUM, 42), _defineProperty(_byBlockchain, BlockchainType.KLAYTN, 42), _defineProperty(_byBlockchain, BlockchainType.BINANCE_SMART_CHAIN, 42), _defineProperty(_byBlockchain, BlockchainType.POLYGON, 42), _defineProperty(_byBlockchain, BlockchainType.FILECOIN, 86), _defineProperty(_byBlockchain, BlockchainType.BITCOIN, 42), _defineProperty(_byBlockchain, BlockchainType.BITCOIN_CASH, 50), _defineProperty(_byBlockchain, BlockchainType.LITECOIN, 42), _byBlockchain);
  return byBlockchain[blockchain];
};
export var isLegacyAddressByBlockchain = function isLegacyAddressByBlockchain(params) {
  var _byBlockchain2;

  var blockchain = params.blockchain,
      address = params.address;
  var byBlockchain = (_byBlockchain2 = {}, _defineProperty(_byBlockchain2, BlockchainType.ETHEREUM, function () {
    return false;
  }), _defineProperty(_byBlockchain2, BlockchainType.KLAYTN, function () {
    return false;
  }), _defineProperty(_byBlockchain2, BlockchainType.BINANCE_SMART_CHAIN, function () {
    return false;
  }), _defineProperty(_byBlockchain2, BlockchainType.POLYGON, function () {
    return false;
  }), _defineProperty(_byBlockchain2, BlockchainType.FILECOIN, function () {
    return false;
  }), _defineProperty(_byBlockchain2, BlockchainType.BITCOIN, function () {
    return false;
  }), _defineProperty(_byBlockchain2, BlockchainType.BITCOIN_CASH, isBchLegacyAddress), _defineProperty(_byBlockchain2, BlockchainType.LITECOIN, isLtcLegacyAddress), _byBlockchain2);
  return byBlockchain[blockchain](address);
};

var isNewAddressByBlockchain = function isNewAddressByBlockchain(params) {
  var _byBlockchain3;

  var blockchain = params.blockchain,
      address = params.address;
  var byBlockchain = (_byBlockchain3 = {}, _defineProperty(_byBlockchain3, BlockchainType.ETHEREUM, function () {
    return false;
  }), _defineProperty(_byBlockchain3, BlockchainType.KLAYTN, function () {
    return false;
  }), _defineProperty(_byBlockchain3, BlockchainType.BINANCE_SMART_CHAIN, function () {
    return false;
  }), _defineProperty(_byBlockchain3, BlockchainType.POLYGON, function () {
    return false;
  }), _defineProperty(_byBlockchain3, BlockchainType.FILECOIN, function () {
    return false;
  }), _defineProperty(_byBlockchain3, BlockchainType.BITCOIN, function () {
    return false;
  }), _defineProperty(_byBlockchain3, BlockchainType.BITCOIN_CASH, isBchNewAddress), _defineProperty(_byBlockchain3, BlockchainType.LITECOIN, isLtcNewAddress), _byBlockchain3);
  return byBlockchain[blockchain](address);
};

var convertToLegacyByBlockchain = function convertToLegacyByBlockchain(params) {
  var _byBlockchain4;

  var blockchain = params.blockchain,
      address = params.address;
  var byBlockchain = (_byBlockchain4 = {}, _defineProperty(_byBlockchain4, BlockchainType.ETHEREUM, function () {
    return null;
  }), _defineProperty(_byBlockchain4, BlockchainType.KLAYTN, function () {
    return null;
  }), _defineProperty(_byBlockchain4, BlockchainType.BINANCE_SMART_CHAIN, function () {
    return null;
  }), _defineProperty(_byBlockchain4, BlockchainType.POLYGON, function () {
    return null;
  }), _defineProperty(_byBlockchain4, BlockchainType.FILECOIN, function () {
    return null;
  }), _defineProperty(_byBlockchain4, BlockchainType.BITCOIN, function () {
    return null;
  }), _defineProperty(_byBlockchain4, BlockchainType.BITCOIN_CASH, convertToBchLegacyAddress), _defineProperty(_byBlockchain4, BlockchainType.LITECOIN, convertToLtcLegacyAddress), _byBlockchain4);
  return byBlockchain[blockchain](address);
};

var convertToNewAddressByBlockchain = function convertToNewAddressByBlockchain(params) {
  var _byBlockchain5;

  var blockchain = params.blockchain,
      address = params.address;
  var byBlockchain = (_byBlockchain5 = {}, _defineProperty(_byBlockchain5, BlockchainType.ETHEREUM, function () {
    return null;
  }), _defineProperty(_byBlockchain5, BlockchainType.KLAYTN, function () {
    return null;
  }), _defineProperty(_byBlockchain5, BlockchainType.BINANCE_SMART_CHAIN, function () {
    return null;
  }), _defineProperty(_byBlockchain5, BlockchainType.POLYGON, function () {
    return null;
  }), _defineProperty(_byBlockchain5, BlockchainType.FILECOIN, function () {
    return null;
  }), _defineProperty(_byBlockchain5, BlockchainType.BITCOIN, function () {
    return null;
  }), _defineProperty(_byBlockchain5, BlockchainType.BITCOIN_CASH, convertToBchNewAddress), _defineProperty(_byBlockchain5, BlockchainType.LITECOIN, convertToLtcNewAddress), _byBlockchain5);
  return byBlockchain[blockchain](address);
};

export var getAddressesIfOldAddressOrNewAddress = function getAddressesIfOldAddressOrNewAddress(params) {
  var blockchain = params.blockchain,
      address = params.address;
  var hasAddressesBlockchain = [BlockchainType.BITCOIN_CASH, BlockchainType.LITECOIN].some(function (targetBlockchain) {
    return targetBlockchain === blockchain;
  });

  if (hasAddressesBlockchain && isLegacyAddressByBlockchain({
    blockchain: blockchain,
    address: address
  })) {
    var _convertToNewAddressB;

    return {
      address: address,
      addressGroup: {
        legacyAddress: address,
        newAddress: (_convertToNewAddressB = convertToNewAddressByBlockchain({
          blockchain: blockchain,
          address: address
        })) !== null && _convertToNewAddressB !== void 0 ? _convertToNewAddressB : ""
      }
    };
  }

  if (hasAddressesBlockchain && isNewAddressByBlockchain({
    blockchain: blockchain,
    address: address
  })) {
    var _convertToLegacyByBlo;

    return {
      address: address,
      addressGroup: {
        legacyAddress: (_convertToLegacyByBlo = convertToLegacyByBlockchain({
          blockchain: blockchain,
          address: address
        })) !== null && _convertToLegacyByBlo !== void 0 ? _convertToLegacyByBlo : "",
        newAddress: address
      }
    };
  }

  return {
    address: address
  };
};
export var getSendUser = function getSendUser(params) {
  var from = params.from,
      to = params.to,
      blockchain = params.blockchain,
      transferType = params.transferType;

  var sendUserMap = function sendUserMap(_ref) {
    var from = _ref.from,
        to = _ref.to;
    return _cond([[_matches({
      blockchain: BlockchainType.ETHEREUM
    }), _constant(from)], [_matches({
      blockchain: BlockchainType.KLAYTN
    }), _constant(from)], [_matches({
      blockchain: BlockchainType.BINANCE_SMART_CHAIN
    }), _constant(from)], [_matches({
      blockchain: BlockchainType.POLYGON
    }), _constant(from)], [_matches({
      blockchain: BlockchainType.BITCOIN,
      transferType: TransferType.DEPOSIT
    }), _constant(to)], [_matches({
      blockchain: BlockchainType.BITCOIN,
      transferType: TransferType.WITHDRAWAL
    }), _constant(from)], [_matches({
      blockchain: BlockchainType.BITCOIN_CASH,
      transferType: TransferType.DEPOSIT
    }), _constant(to)], [_matches({
      blockchain: BlockchainType.BITCOIN_CASH,
      transferType: TransferType.WITHDRAWAL
    }), _constant(from)], [_matches({
      blockchain: BlockchainType.LITECOIN,
      transferType: TransferType.DEPOSIT
    }), _constant(to)], [_matches({
      blockchain: BlockchainType.LITECOIN,
      transferType: TransferType.WITHDRAWAL
    }), _constant(from)]]);
  };

  return sendUserMap({
    from: from,
    to: to
  })({
    blockchain: blockchain,
    transferType: transferType
  });
};
export var getReceiveUser = function getReceiveUser(params) {
  var from = params.from,
      to = params.to,
      blockchain = params.blockchain,
      transferType = params.transferType;

  var receiveUserMap = function receiveUserMap(_ref2) {
    var from = _ref2.from,
        to = _ref2.to;
    return _cond([[_matches({
      blockchain: BlockchainType.ETHEREUM
    }), _constant(to)], [_matches({
      blockchain: BlockchainType.KLAYTN
    }), _constant(to)], [_matches({
      blockchain: BlockchainType.BINANCE_SMART_CHAIN
    }), _constant(to)], [_matches({
      blockchain: BlockchainType.POLYGON
    }), _constant(to)], [_matches({
      blockchain: BlockchainType.BITCOIN,
      transferType: TransferType.DEPOSIT
    }), _constant(from)], [_matches({
      blockchain: BlockchainType.BITCOIN,
      transferType: TransferType.WITHDRAWAL
    }), _constant(to)], [_matches({
      blockchain: BlockchainType.BITCOIN_CASH,
      transferType: TransferType.DEPOSIT
    }), _constant(from)], [_matches({
      blockchain: BlockchainType.BITCOIN_CASH,
      transferType: TransferType.WITHDRAWAL
    }), _constant(to)], [_matches({
      blockchain: BlockchainType.LITECOIN,
      transferType: TransferType.DEPOSIT
    }), _constant(from)], [_matches({
      blockchain: BlockchainType.LITECOIN,
      transferType: TransferType.WITHDRAWAL
    }), _constant(to)]]);
  };

  return receiveUserMap({
    from: from,
    to: to
  })({
    blockchain: blockchain,
    transferType: transferType
  });
};