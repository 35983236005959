var __jsx = React.createElement;
import React from "react";
import EmptyStates from "./EmptyStates";
import { translateComponent } from "../../locales";
import { withTranslation } from "../../libs/i18n";

var FlushTransactionsEmptyStates = function FlushTransactionsEmptyStates(props) {
  var onLink = props.onLink,
      t = props.t;
  return __jsx(EmptyStates, {
    title: translateComponent(["flushTransactionsEmptyStates", "title"], t),
    content: translateComponent(["flushTransactionsEmptyStates", "description"], t),
    buttonProps: {
      onClick: onLink
    },
    buttonText: translateComponent(["flushTransactionsEmptyStates", "submitButton"], t)
  });
};

export default withTranslation(["common", "component"])(FlushTransactionsEmptyStates);