var __jsx = React.createElement;
import React, { useCallback } from "react";
import EmptyStates from "./EmptyStates";
import MaterialIcon from "../Icon/MaterialIcon";
import { isBrowser } from "../../utils/browser";
import { translateComponent } from "../../locales";
import { withTranslation } from "../../libs/i18n";

var SmartContractEmptyStates = function SmartContractEmptyStates(props) {
  var t = props.t;
  var navigateTo = useCallback(function () {
    isBrowser && window.open("https://henesis.gitbook.io/henesis-wallet/integrate-with-api/api-list/v2/eth-klay/user-wallet/send-transaction-from-user-wallet");
  }, []);
  return __jsx(EmptyStates, {
    title: translateComponent(["smartcontractEmptyStates", "title"], t),
    content: translateComponent(["smartcontractEmptyStates", "description"], t),
    buttonProps: {
      startIcon: __jsx(MaterialIcon, {
        name: "description"
      }),
      onClick: navigateTo
    },
    buttonText: translateComponent(["smartcontractEmptyStates", "submitButton"], t)
  });
};

export default withTranslation()(SmartContractEmptyStates);