import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import * as React from "react";

function SvgComponent(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M0 4a4 4 0 014-4h16a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4zm8.893 6.723L12 7.616l3.109 3.109 1.808-1.808L12 4 7.085 8.915l1.808 1.808zM4 12l1.808-1.808L7.616 12l-1.808 1.808L4 12zm4.893 1.277L12 16.384l3.109-3.109 1.809 1.807-.001.001L12 20l-4.915-4.915-.003-.003 1.81-1.805zM16.384 12l1.808-1.808L20 12l-1.808 1.808L16.384 12zm-2.55-.002h.002l-.001.002L12 13.836l-1.833-1.834-.002-.002.002-.003.321-.321.156-.156L12 10.164 13.835 12z",
    fill: "#F3BA2F"
  }));
}

export default SvgComponent;