import _defineProperty from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
import _get from "lodash/get";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { SDK } from "@haechi-labs/henesis-wallet-core";
import { baseUrls } from "@haechi-labs/henesis-wallet-core/lib/utils/url";
import { HttpClient, enhancedBlockchainClient } from "@haechi-labs/henesis-wallet-core/lib/httpClient";
import { getRootStore } from "../stores/Store";
import { env } from "./env";
import { addMonitor } from "../ReactotronConfig";
import { addMonitor as addSentryMonitor } from "./sentry";

var getBaseUrl = function getBaseUrl() {
  var baseUrl = baseUrls.get(env.SDK_ENV.env);

  if (env.SDK_ENV.url) {
    return env.SDK_ENV.url;
  }

  return baseUrl !== null && baseUrl !== void 0 ? baseUrl : "";
};

var getHttpClient = function getHttpClient() {
  var accessToken = getRootStore(false).authStore.apiAccessToken();
  var secret = getRootStore(false).authStore.apiSecret;
  var client = new HttpClient(_objectSpread({
    secret: secret,
    accessToken: accessToken,
    url: getBaseUrl(),
    origin: {
      remoteAddress: "",
      forwardedFor: ""
    }
  }, env.SDK_ENV));
  return client;
};

export var getClientByBlockchain = function getClientByBlockchain(blockchain) {
  var client = getHttpClient();
  addMonitor(_get(client, ["client"]));
  addSentryMonitor(_get(client, ["client"]));
  return enhancedBlockchainClient(client, blockchain);
};
export var getWalletSDK = function getWalletSDK() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    accessToken: getRootStore(false).authStore.apiAccessToken(),
    secret: getRootStore(false).authStore.apiSecret
  };
  var accessToken = options.accessToken,
      secret = options.secret,
      url = options.url;
  var sdk = new SDK(_objectSpread(_objectSpread({}, env.SDK_ENV), {}, {
    accessToken: accessToken,
    secret: secret,
    url: url ? url : env.SDK_ENV.url
  }));
  addMonitor(_get(sdk, ["client", "client"]));
  addSentryMonitor(_get(sdk, ["client", "client"]));
  return sdk;
};