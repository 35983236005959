import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from "react";

var SvgComponent = function SvgComponent(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none"
  }, props), __jsx("path", {
    d: "M11.761 11.697L18.74 4.68 12.316 2 5.893 17.617l5.869-5.92zM19.305 18.817l-6.822-6.873 6.822-6.872A10.038 10.038 0 0122 11.944c0 2.657-1.02 5.079-2.695 6.873zM5.272 19.197l6.734-6.782 6.733 6.782L12.006 22l-6.734-2.803zM4.673 18.794A10.088 10.088 0 012 11.944l9.373-9.44-6.7 16.29z",
    fill: "#000"
  }));
};

export default SvgComponent;