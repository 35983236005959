import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import * as React from "react";

function SvgComponent(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none"
  }, props), __jsx("rect", {
    width: 24,
    height: 24,
    rx: 4,
    fill: "#0B868F"
  }), __jsx("path", {
    d: "M11.998 15.732l5.252-2.72-5.252 6.488-5.248-6.488 5.248 2.72z",
    fill: "#fff"
  }), __jsx("path", {
    d: "M11.998 4.5l5.249 7.64-5.249 2.72-5.248-2.72 5.248-7.64z",
    fill: "#fff"
  }));
}

export default SvgComponent;