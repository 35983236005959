import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from "react";
import EmptyStates from "../EmptyStates";
import MaterialIcon from "../../Icon/MaterialIcon";
import { translateComponent } from "../../../locales";
import { withTranslation } from "../../../libs/i18n";

var NFTTransferEmptyStates = function NFTTransferEmptyStates(props) {
  var onDeposit = props.onDeposit,
      t = props.t;
  return __jsx(EmptyStates, _extends({
    title: translateComponent(["nftTransferEmptyStates", "title"], t),
    content: translateComponent(["nftTransferEmptyStates", "description"], t)
  }, onDeposit ? {
    buttonProps: {
      startIcon: __jsx(MaterialIcon, {
        name: "file_download"
      }),
      onClick: onDeposit
    },
    buttonText: translateComponent(["nftTransferEmptyStates", "submitButton"], t)
  } : {}));
};

export default withTranslation()(NFTTransferEmptyStates);