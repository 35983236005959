import _defineProperty from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
import _get from "lodash/get";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { EthMasterWallet, EthWallet } from "@haechi-labs/henesis-wallet-core/lib/eth/wallet";
import { EthDepositAddress } from "@haechi-labs/henesis-wallet-core/lib/eth/depositAddress";
import { EthUserWallet } from "@haechi-labs/henesis-wallet-core/lib/eth/userWallet";
import { BtcMasterWallet } from "@haechi-labs/henesis-wallet-core/lib/btc/wallet";
import { BlockchainType } from "@haechi-labs/henesis-wallet-core/lib/blockchain";
import { BtcKeyChains } from "@haechi-labs/henesis-wallet-core/lib/btc/keychains";
import { EthKeychains } from "@haechi-labs/henesis-wallet-core";
import { LtcMasterWallet } from "@haechi-labs/henesis-wallet-core/lib/ltc/wallet";
import { LtcKeyChains } from "@haechi-labs/henesis-wallet-core/lib/ltc/keychains";
import { getClientByBlockchain } from "../libs/walletSDK";
import { env } from "../libs/env";
import { isLessThanWalletV4 } from "../utils/version";
import { FilDepositAddress } from "@haechi-labs/henesis-wallet-core/lib/fil/depositAddress";
import { FilMasterWallet } from "@haechi-labs/henesis-wallet-core/lib/fil/wallet";
import { FilKeychains } from "@haechi-labs/henesis-wallet-core/lib/fil";
import { BchMasterWallet } from "@haechi-labs/henesis-wallet-core/lib/bch/wallet";
import { BchKeyChains } from "@haechi-labs/henesis-wallet-core/lib/bch/keychains";
export var makeWalletDataToWallet = function makeWalletDataToWallet(blockchain, walletData) {
  var _byBlockchain;

  var data = walletData;

  var version = _get(data, ["version"], "");

  var byBlockchain = (_byBlockchain = {}, _defineProperty(_byBlockchain, BlockchainType.ETHEREUM, function () {
    return isLessThanWalletV4(version) ? new EthMasterWallet(getClientByBlockchain(blockchain), _objectSpread({}, data), new EthKeychains(blockchain), blockchain) : new EthWallet(getClientByBlockchain(blockchain), _objectSpread({}, data), new EthKeychains(blockchain), blockchain);
  }), _defineProperty(_byBlockchain, BlockchainType.KLAYTN, function () {
    return new EthMasterWallet(getClientByBlockchain(blockchain), _objectSpread({}, data), new EthKeychains(blockchain), blockchain);
  }), _defineProperty(_byBlockchain, BlockchainType.BINANCE_SMART_CHAIN, function () {
    return new EthMasterWallet(getClientByBlockchain(blockchain), _objectSpread({}, data), new EthKeychains(blockchain), blockchain);
  }), _defineProperty(_byBlockchain, BlockchainType.POLYGON, function () {
    return new EthMasterWallet(getClientByBlockchain(blockchain), _objectSpread({}, data), new EthKeychains(blockchain), blockchain);
  }), _defineProperty(_byBlockchain, BlockchainType.FILECOIN, function () {
    return new FilMasterWallet(getClientByBlockchain(blockchain), _objectSpread({}, data), new FilKeychains(env.SDK_ENV.env));
  }), _defineProperty(_byBlockchain, BlockchainType.BITCOIN, function () {
    return new BtcMasterWallet(_objectSpread({}, data), getClientByBlockchain(blockchain), new BtcKeyChains(env.SDK_ENV.env), env.SDK_ENV.env);
  }), _defineProperty(_byBlockchain, BlockchainType.BITCOIN_CASH, function () {
    return new BchMasterWallet(_objectSpread({}, data), getClientByBlockchain(blockchain), new BchKeyChains(env.SDK_ENV.env), env.SDK_ENV.env);
  }), _defineProperty(_byBlockchain, BlockchainType.LITECOIN, function () {
    return new LtcMasterWallet(_objectSpread({}, data), getClientByBlockchain(blockchain), new LtcKeyChains(env.SDK_ENV.env), env.SDK_ENV.env);
  }), _byBlockchain);
  return byBlockchain[blockchain]();
};
export var makeWalletDataToDepositAddress = function makeWalletDataToDepositAddress(blockchain, masterWallet, depositAddressData) {
  var _byBlockchain2;

  var data = masterWallet.getData();

  var getEthAndKlayUserWallet = function getEthAndKlayUserWallet() {
    return new EthDepositAddress(getClientByBlockchain(blockchain), _objectSpread({}, data), new EthKeychains(blockchain), _objectSpread({}, depositAddressData), blockchain);
  };

  var getBscUserWallet = getEthAndKlayUserWallet;
  var getPolygonUserWallet = getEthAndKlayUserWallet;

  var getFilDepositAddress = function getFilDepositAddress() {
    return new FilDepositAddress(getClientByBlockchain(blockchain), _objectSpread({}, data), new FilKeychains(env.SDK_ENV.env), // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _objectSpread({}, depositAddressData));
  };

  var getDefaultDepositAddress = function getDefaultDepositAddress() {
    return new EthDepositAddress(getClientByBlockchain(blockchain), _objectSpread({}, data), new EthKeychains(blockchain), _objectSpread({}, depositAddressData), blockchain);
  };

  var byBlockchain = (_byBlockchain2 = {}, _defineProperty(_byBlockchain2, BlockchainType.ETHEREUM, getEthAndKlayUserWallet), _defineProperty(_byBlockchain2, BlockchainType.KLAYTN, getEthAndKlayUserWallet), _defineProperty(_byBlockchain2, BlockchainType.BINANCE_SMART_CHAIN, getBscUserWallet), _defineProperty(_byBlockchain2, BlockchainType.POLYGON, getPolygonUserWallet), _defineProperty(_byBlockchain2, BlockchainType.FILECOIN, getFilDepositAddress), _defineProperty(_byBlockchain2, BlockchainType.BITCOIN, getDefaultDepositAddress), _defineProperty(_byBlockchain2, BlockchainType.BITCOIN_CASH, getDefaultDepositAddress), _defineProperty(_byBlockchain2, BlockchainType.LITECOIN, getDefaultDepositAddress), _byBlockchain2);
  return byBlockchain[blockchain]();
};
export var makeWalletDataToUserWallet = function makeWalletDataToUserWallet(blockchain, masterWallet, userWalletData) {
  var _byBlockchain3;

  var data = masterWallet.getData();

  var getEthAndKlayUserWallet = function getEthAndKlayUserWallet() {
    return new EthUserWallet(getClientByBlockchain(blockchain), _objectSpread({}, data), new EthKeychains(blockchain), _objectSpread({}, userWalletData), blockchain);
  };

  var getBscUserWallet = getEthAndKlayUserWallet;
  var getPolygonUserWallet = getEthAndKlayUserWallet;
  var getFilUserWallet = getEthAndKlayUserWallet;

  var getDefaultUserWallet = function getDefaultUserWallet() {
    return new EthUserWallet(getClientByBlockchain(blockchain), _objectSpread({}, data), new BtcKeyChains(env.SDK_ENV.env), _objectSpread({}, userWalletData), blockchain);
  };

  var byBlockchain = (_byBlockchain3 = {}, _defineProperty(_byBlockchain3, BlockchainType.ETHEREUM, getEthAndKlayUserWallet), _defineProperty(_byBlockchain3, BlockchainType.KLAYTN, getFilUserWallet), _defineProperty(_byBlockchain3, BlockchainType.BINANCE_SMART_CHAIN, getBscUserWallet), _defineProperty(_byBlockchain3, BlockchainType.POLYGON, getPolygonUserWallet), _defineProperty(_byBlockchain3, BlockchainType.FILECOIN, getEthAndKlayUserWallet), _defineProperty(_byBlockchain3, BlockchainType.BITCOIN, getDefaultUserWallet), _defineProperty(_byBlockchain3, BlockchainType.BITCOIN_CASH, getDefaultUserWallet), _defineProperty(_byBlockchain3, BlockchainType.LITECOIN, getDefaultUserWallet), _byBlockchain3);
  return byBlockchain[blockchain]();
};