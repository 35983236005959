var __jsx = React.createElement;
import React from "react";
import EmptyStates from "../EmptyStates";
import MaterialIcon from "../../Icon/MaterialIcon";
import { translateComponent } from "../../../locales";
import { withTranslation } from "../../../libs/i18n";

var NFTBalancePickerEmptyStates = function NFTBalancePickerEmptyStates(props) {
  var onDeposit = props.onDeposit,
      t = props.t;
  return __jsx(EmptyStates, {
    title: translateComponent(["nftBalancePickerEmptyStates", "title"], t),
    content: translateComponent(["nftBalancePickerEmptyStates", "description"], t),
    buttonProps: {
      startIcon: __jsx(MaterialIcon, {
        name: "file_download"
      }),
      onClick: onDeposit
    },
    buttonText: translateComponent(["nftBalancePickerEmptyStates", "submitButton"], t)
  });
};

export default withTranslation(["common", "component"])(NFTBalancePickerEmptyStates);