var __jsx = React.createElement;
import React from "react";
import TemplateCompleteDialog from "../TemplateCompleteDialog";
import { translateComponent } from "../../../locales";
import { withTranslation } from "../../../libs/i18n";

var RegisterAllowedAddressCompleteDialog = function RegisterAllowedAddressCompleteDialog(props) {
  var onConfirm = props.onConfirm,
      t = props.t;
  return __jsx(TemplateCompleteDialog, {
    size: "medium",
    title: translateComponent(["registerAllowedAddressCompleteDialog", "title"], t),
    description: translateComponent(["registerAllowedAddressCompleteDialog", "description"], t),
    onConfirm: onConfirm
  });
};

export default withTranslation(["common", "component"])(RegisterAllowedAddressCompleteDialog);