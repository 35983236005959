import _defineProperty from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
import { t } from "./i18n";
import { translate } from "../locales";
export var MessageCode;

(function (MessageCode) {
  MessageCode["VERIFIED_IP"] = "1";
})(MessageCode || (MessageCode = {}));

export var messageByCode = function messageByCode(messageCode) {
  var defaultMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";

  var messages = _defineProperty({}, MessageCode.VERIFIED_IP, translate(["libs", "message", "VERIFIED_IP"], t));

  return messages[messageCode] || defaultMessage;
};