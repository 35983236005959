import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from "react";
import EmptyStates from "./EmptyStates";
import MaterialIcon from "../Icon/MaterialIcon";
import { palette } from "../../themes/palette";
import { translateComponent } from "../../locales";
import { withTranslation } from "../../libs/i18n";

var SearchEmptyStates = function SearchEmptyStates(props) {
  var onReset = props.onReset,
      t = props.t;
  return __jsx(EmptyStates, _extends({
    title: translateComponent(["searchEmptyStates", "title"], t),
    content: translateComponent(["searchEmptyStates", "description"], t)
  }, onReset ? {
    buttonProps: {
      startIcon: __jsx(MaterialIcon, {
        name: "refresh",
        fontSize: 20,
        color: palette.white.main
      }),
      onClick: onReset
    },
    buttonText: translateComponent(["searchEmptyStates", "submitButton"], t)
  } : {}));
};

export default withTranslation(["common", "component"])(SearchEmptyStates);