import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["children", "isLoading", "disabled", "size", "startIcon", "progressProps"];
var __jsx = React.createElement;
import React from "react";
import MaterialButton from "./MaterialButton";
import Progress from "../Progress/Progress";
import { palette } from "../../themes/palette";

var LoadingButton = function LoadingButton(props) {
  var children = props.children,
      isLoading = props.isLoading,
      disabled = props.disabled,
      size = props.size,
      startIcon = props.startIcon,
      _props$progressProps = props.progressProps,
      progressProps = _props$progressProps === void 0 ? {
    size: "small"
  } : _props$progressProps,
      rest = _objectWithoutProperties(props, _excluded);

  return __jsx(MaterialButton, _extends({}, rest, {
    disabled: disabled || isLoading,
    startIcon: isLoading ? __jsx(Progress, _extends({
      color: palette.primary.main
    }, progressProps)) : startIcon,
    size: size
  }), children);
};

export default LoadingButton;