var __jsx = React.createElement;
import React from "react";
import EmptyStates from "./EmptyStates";
import MaterialIcon from "../Icon/MaterialIcon";
import { translateComponent } from "../../locales";
import { withTranslation } from "../../libs/i18n";

var BalanceEmptyStates = function BalanceEmptyStates(props) {
  var onDeposit = props.onDeposit,
      t = props.t;
  return __jsx(EmptyStates, {
    title: translateComponent(["balanceEmptyStates", "title"], t),
    content: translateComponent(["balanceEmptyStates", "description"], t),
    buttonProps: {
      startIcon: __jsx(MaterialIcon, {
        name: "file_download"
      }),
      onClick: onDeposit
    },
    buttonText: translateComponent(["balanceEmptyStates", "submitButton"], t)
  });
};

export default withTranslation(["common", "component"])(BalanceEmptyStates);