import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import * as React from "react";

function SvgComponent(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M4 0a4 4 0 00-4 4v16a4 4 0 004 4h16a4 4 0 004-4V4a4 4 0 00-4-4H4zm2 6.75A2.25 2.25 0 018.25 4.5h7.5A2.25 2.25 0 0118 6.75V19.5H6V6.75zM16.5 6h-9v5.25h9V6zm-3.294 8.844l-1.208-1.689-1.204 1.689c-.175.248-.277.55-.277.879 0 .836.661 1.51 1.481 1.51.824 0 1.482-.674 1.482-1.51a1.49 1.49 0 00-.274-.88z",
    fill: "#006D85"
  }));
}

export default SvgComponent;