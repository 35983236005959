import { types } from "mobx-state-tree";
import { env } from "../libs/env";
import { DEFAULT_APPLY_AGGREGATE_BALANCES, firebaseRemoteConfig } from "../libs/remoteConfig";
var RemoteConfigStore = types.model("RemoteConfigStore", {
  isApplyAggregateBalanceByBlockchain: types.frozen(DEFAULT_APPLY_AGGREGATE_BALANCES)
}).views(function (self) {
  return {
    isApplyAggregateBalanceBy: function isApplyAggregateBalanceBy(blockchain) {
      var _self$isApplyAggregat;

      return Boolean((_self$isApplyAggregat = self.isApplyAggregateBalanceByBlockchain[blockchain]) === null || _self$isApplyAggregat === void 0 ? void 0 : _self$isApplyAggregat[env.REACT_ENV]);
    }
  };
}).actions(function (self) {
  var initialize = function initialize() {
    self.isApplyAggregateBalanceByBlockchain = firebaseRemoteConfig().isApplyAggregateBalances();
  };

  return {
    initialize: initialize
  };
});
export default RemoteConfigStore;