import _toConsumableArray from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
import _identity from "lodash/identity";
import _values from "lodash/values";

var _blockchainItems;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { BlockchainType } from "@haechi-labs/henesis-wallet-core/lib/blockchain";
import { WalletType } from "@haechi-labs/henesis-wallet-core/lib/wallet";
import { remove0xPrefix, removeUnderscore, prefixTestnet } from "./string";
import { env, isTestnetAndDevelopment, isTestnetStaging } from "../libs/env";
import { BTC_DECIMAL, BCH_DECIMAL, ETH_AND_KLAY_DECIMAL, FILCOIN_DECIMAL, LTC_DECIMAL, BSC_DECIMAL, POLYGON_DECIMAL } from "./number";
import { translate } from "../locales";
import { filterEmpty } from "./array";
import { i18n } from "../libs/i18n";
import { palette } from "../themes/palette";

var makeEthereumAddressLink = function makeEthereumAddressLink(address) {
  if (isTestnetAndDevelopment) {
    return "https://goerli.etherscan.io/address/".concat(address);
  }

  return "https://etherscan.io/address/".concat(address);
};

var makeEthereumTxHashLink = function makeEthereumTxHashLink(txHash) {
  if (isTestnetAndDevelopment) {
    return "https://goerli.etherscan.io/tx/".concat(txHash);
  }

  return "https://etherscan.io/tx/".concat(txHash);
};

var makeEthereumNFTLink = function makeEthereumNFTLink(address) {
  if (isTestnetAndDevelopment) {
    return "https://goerli.etherscan.io/token/".concat(address);
  }

  return "https://etherscan.io/token/".concat(address);
};

var makeKlaytnTxHashLink = function makeKlaytnTxHashLink(txHash) {
  if (isTestnetAndDevelopment) {
    return "https://baobab.scope.klaytn.com/tx/".concat(txHash);
  }

  return "https://scope.klaytn.com/tx/".concat(txHash);
};

var makeKlaytnAddressLink = function makeKlaytnAddressLink(address) {
  if (isTestnetAndDevelopment) {
    return "https://baobab.scope.klaytn.com/account/".concat(address);
  }

  return "https://scope.klaytn.com/account/".concat(address);
};

var makeKlaytnNFTLink = function makeKlaytnNFTLink(address) {
  if (isTestnetAndDevelopment) {
    return "https://baobab.scope.klaytn.com/token/".concat(address);
  }

  return "https://scope.klaytn.com/token/".concat(address);
};

var makeFilecoinTxHashLink = function makeFilecoinTxHashLink(txHash) {
  if (isTestnetAndDevelopment) {
    return "https://calibration.filscan.io/#/tipset/message-detail?cid=".concat(txHash);
  }

  return "https://filfox.info/en/message/".concat(txHash);
};

var makeFilecoinAddressLink = function makeFilecoinAddressLink(address) {
  if (isTestnetAndDevelopment) {
    return "https://calibration.filscan.io/#/tipset/address-detail?address=".concat(address);
  }

  return "https://filfox.info/en/address/".concat(address);
};

var makeBitcoinTxHashLink = function makeBitcoinTxHashLink(txHash) {
  var removed0xTxHash = remove0xPrefix(txHash);

  if (isTestnetAndDevelopment) {
    return "https://blockstream.info/testnet/tx/".concat(removed0xTxHash);
  }

  return "https://blockstream.info/tx/".concat(removed0xTxHash);
};

var makeBitcoinCashTxHashLink = function makeBitcoinCashTxHashLink(txHash) {
  var removed0xTxHash = remove0xPrefix(txHash);

  if (isTestnetAndDevelopment) {
    return "https://www.blockchain.com/bch-testnet/tx/".concat(removed0xTxHash);
  }

  return "https://www.blockchain.com/bch/tx/".concat(removed0xTxHash);
};

var makeLitecoinTxHashLink = function makeLitecoinTxHashLink(txHash) {
  var removed0xTxHash = remove0xPrefix(txHash);

  if (isTestnetAndDevelopment) {
    return "https://blockexplorer.one/litecoin/testnet/tx/".concat(removed0xTxHash);
  }

  return "https://live.blockcypher.com/ltc/tx/".concat(removed0xTxHash);
};

var makeBscTxHashLink = function makeBscTxHashLink(txHash) {
  if (isTestnetAndDevelopment) {
    return "https://testnet.bscscan.com/tx/".concat(txHash);
  }

  return "https://bscscan.com/tx/".concat(txHash);
};

var makeBscAddressLink = function makeBscAddressLink(address) {
  if (isTestnetAndDevelopment) {
    return "https://testnet.bscscan.com/address/".concat(address);
  }

  return "https://bscscan.com/address/".concat(address);
};

var makePolygonTxHashLink = function makePolygonTxHashLink(txHash) {
  if (isTestnetAndDevelopment) {
    return "https://mumbai.polygonscan.com/tx/".concat(txHash);
  }

  return "https://polygonscan.com/tx/".concat(txHash);
};

var makePolygonAddressLink = function makePolygonAddressLink(address) {
  if (isTestnetAndDevelopment) {
    return "https://mumbai.polygonscan.com/address/".concat(address);
  }

  return "https://polygonscan.com/address/".concat(address);
};

export var getEmptyBalanceByBlockchain = function getEmptyBalanceByBlockchain(blockchain) {
  var _blockchainItems$bloc = blockchainItems[blockchain],
      coinId = _blockchainItems$bloc.coinId,
      symbol = _blockchainItems$bloc.unit,
      decimals = _blockchainItems$bloc.decimals;

  var _getCoinTypeByBlockch = getCoinTypeByBlockchain({
    blockchain: blockchain,
    isApplyNFTWalletVersion: true
  }),
      coinType = _getCoinTypeByBlockch.coinType;

  return {
    coinId: coinId !== null && coinId !== void 0 ? coinId : null,
    coinType: coinType,
    amount: "0",
    spendableAmount: "0",
    name: "",
    symbol: symbol,
    aggregatedAmount: "0",
    decimals: decimals
  };
};
export var getBlockchainTypeByNoUnderscoreBlockchain = function getBlockchainTypeByNoUnderscoreBlockchain(noUnderscoreBlockchain) {
  var _items$find;

  var items = _values(blockchainItems);

  return (_items$find = items.find(function (item) {
    return item.noUnderscoreBlockchain === noUnderscoreBlockchain;
  })) === null || _items$find === void 0 ? void 0 : _items$find.blockchain;
};
var blockchainItems = (_blockchainItems = {}, _defineProperty(_blockchainItems, BlockchainType.ETHEREUM, {
  coinId: 2,
  blockchain: BlockchainType.ETHEREUM,
  noUnderscoreBlockchain: removeUnderscore(BlockchainType.ETHEREUM.toString()),
  bgColor: palette.cyan.dark,
  unit: "ETH",
  decimals: ETH_AND_KLAY_DECIMAL,
  gasPriceUnit: "Gwei",
  makeTxHashLink: makeEthereumTxHashLink,
  makeHopTxHashLink: makeEthereumTxHashLink,
  // TODO
  makeNFTLink: makeEthereumNFTLink,
  makeAddressLink: makeEthereumAddressLink
}), _defineProperty(_blockchainItems, BlockchainType.KLAYTN, {
  coinId: 1,
  blockchain: BlockchainType.KLAYTN,
  noUnderscoreBlockchain: removeUnderscore(BlockchainType.KLAYTN.toString()),
  bgColor: "#E51244",
  unit: "KLAY",
  decimals: ETH_AND_KLAY_DECIMAL,
  gasPriceUnit: "Stone",
  makeTxHashLink: makeKlaytnTxHashLink,
  makeHopTxHashLink: makeKlaytnTxHashLink,
  // TODO
  makeNFTLink: makeKlaytnNFTLink,
  makeAddressLink: makeKlaytnAddressLink
}), _defineProperty(_blockchainItems, BlockchainType.BINANCE_SMART_CHAIN, {
  blockchain: BlockchainType.BINANCE_SMART_CHAIN,
  noUnderscoreBlockchain: removeUnderscore(BlockchainType.BINANCE_SMART_CHAIN.toString()),
  bgColor: "#F3BA2F",
  unit: "BNB",
  decimals: BSC_DECIMAL,
  gasPriceUnit: "Gwei",
  makeTxHashLink: makeBscTxHashLink,
  makeHopTxHashLink: makeBscTxHashLink,
  makeAddressLink: makeBscAddressLink
}), _defineProperty(_blockchainItems, BlockchainType.POLYGON, {
  blockchain: BlockchainType.POLYGON,
  noUnderscoreBlockchain: removeUnderscore(BlockchainType.POLYGON.toString()),
  bgColor: palette.purple.dark,
  unit: "MATIC",
  decimals: POLYGON_DECIMAL,
  gasPriceUnit: "Gwei",
  makeTxHashLink: makePolygonTxHashLink,
  makeHopTxHashLink: makePolygonTxHashLink,
  makeAddressLink: makePolygonAddressLink
}), _defineProperty(_blockchainItems, BlockchainType.FILECOIN, {
  blockchain: BlockchainType.FILECOIN,
  noUnderscoreBlockchain: removeUnderscore(BlockchainType.FILECOIN.toString()),
  bgColor: "#3376DB",
  unit: "FIL",
  decimals: FILCOIN_DECIMAL,
  gasPriceUnit: "Gwei",
  makeTxHashLink: makeFilecoinTxHashLink,
  makeAddressLink: makeFilecoinAddressLink
}), _defineProperty(_blockchainItems, BlockchainType.BITCOIN, {
  blockchain: BlockchainType.BITCOIN,
  noUnderscoreBlockchain: removeUnderscore(BlockchainType.BITCOIN.toString()),
  bgColor: "#E84809",
  unit: "BTC",
  decimals: BTC_DECIMAL,
  gasPriceUnit: "BTC",
  makeTxHashLink: makeBitcoinTxHashLink,
  makeAddressLink: _identity
}), _defineProperty(_blockchainItems, BlockchainType.BITCOIN_CASH, {
  blockchain: BlockchainType.BITCOIN_CASH,
  noUnderscoreBlockchain: removeUnderscore(BlockchainType.BITCOIN_CASH.toString()),
  bgColor: "#8DC351",
  unit: "BCH",
  decimals: BCH_DECIMAL,
  gasPriceUnit: "BCH",
  makeTxHashLink: makeBitcoinCashTxHashLink,
  makeAddressLink: _identity
}), _defineProperty(_blockchainItems, BlockchainType.LITECOIN, {
  blockchain: BlockchainType.LITECOIN,
  noUnderscoreBlockchain: removeUnderscore(BlockchainType.LITECOIN.toString()),
  bgColor: "#345D9D",
  unit: "LTC",
  decimals: LTC_DECIMAL,
  gasPriceUnit: "LTC",
  makeTxHashLink: makeLitecoinTxHashLink,
  makeAddressLink: _identity
}), _blockchainItems);
export var getBlockchainItem = function getBlockchainItem(blockchain) {
  return blockchainItems[blockchain];
};
export var getCoinTypeByBlockchain = function getCoinTypeByBlockchain(params) {
  var _byBlockchain;

  var isApplyNFTWalletVersion = params.isApplyNFTWalletVersion,
      blockchain = params.blockchain;
  var byBlockchain = (_byBlockchain = {}, _defineProperty(_byBlockchain, BlockchainType.ETHEREUM, {
    coinType: "ERC20",
    nftCoinType: "ERC721"
  }), _defineProperty(_byBlockchain, BlockchainType.KLAYTN, {
    coinType: isApplyNFTWalletVersion ? "KIP7" : "KCT",
    nftCoinType: "KIP17"
  }), _defineProperty(_byBlockchain, BlockchainType.BINANCE_SMART_CHAIN, {
    coinType: "BEP-20"
  }), _defineProperty(_byBlockchain, BlockchainType.POLYGON, {
    coinType: "ERC20",
    nftCoinType: "ERC721"
  }), _defineProperty(_byBlockchain, BlockchainType.FILECOIN, {
    coinType: "FIL"
  }), _defineProperty(_byBlockchain, BlockchainType.BITCOIN, {
    coinType: "BTC"
  }), _defineProperty(_byBlockchain, BlockchainType.BITCOIN_CASH, {
    coinType: "BCH"
  }), _defineProperty(_byBlockchain, BlockchainType.LITECOIN, {
    coinType: "LTC"
  }), _byBlockchain);
  return byBlockchain[blockchain];
};
export var getBlockchainItemBy = function getBlockchainItemBy(blockchain, t) {
  var _byBlockchain2;

  var byBlockchain = (_byBlockchain2 = {}, _defineProperty(_byBlockchain2, BlockchainType.ETHEREUM, _objectSpread({
    label: prefixTestnet(translate(["utils", "blockchain", "getBlockchainItemBy", "ethereum"], t)),
    coinName: prefixTestnet(translate(["utils", "blockchain", "getCoinNameBy", "ethereum"], t)),
    noPrefixLabel: translate(["utils", "blockchain", "getBlockchainItemBy", "ethereum"], t)
  }, blockchainItems[BlockchainType.ETHEREUM])), _defineProperty(_byBlockchain2, BlockchainType.KLAYTN, _objectSpread({
    label: prefixTestnet(translate(["utils", "blockchain", "getBlockchainItemBy", "klaytn"], t)),
    coinName: prefixTestnet(translate(["utils", "blockchain", "getCoinNameBy", "klaytn"], t)),
    noPrefixLabel: translate(["utils", "blockchain", "getBlockchainItemBy", "klaytn"], t)
  }, blockchainItems[BlockchainType.KLAYTN])), _defineProperty(_byBlockchain2, BlockchainType.BINANCE_SMART_CHAIN, _objectSpread({
    label: prefixTestnet(translate(["utils", "blockchain", "getBlockchainItemBy", "bcs"], t)),
    coinName: prefixTestnet(translate(["utils", "blockchain", "getCoinNameBy", "bcs"], t)),
    noPrefixLabel: translate(["utils", "blockchain", "getBlockchainItemBy", "bcs"], t)
  }, blockchainItems[BlockchainType.BINANCE_SMART_CHAIN])), _defineProperty(_byBlockchain2, BlockchainType.POLYGON, _objectSpread({
    label: prefixTestnet(translate(["utils", "blockchain", "getBlockchainItemBy", "polygon"], t)),
    coinName: prefixTestnet(translate(["utils", "blockchain", "getCoinNameBy", "polygon"], t)),
    noPrefixLabel: translate(["utils", "blockchain", "getBlockchainItemBy", "polygon"], t)
  }, blockchainItems[BlockchainType.POLYGON])), _defineProperty(_byBlockchain2, BlockchainType.FILECOIN, _objectSpread({
    label: prefixTestnet(translate(["utils", "blockchain", "getBlockchainItemBy", "filecoin"], t)),
    coinName: prefixTestnet(translate(["utils", "blockchain", "getCoinNameBy", "filecoin"], t)),
    noPrefixLabel: translate(["utils", "blockchain", "getBlockchainItemBy", "filecoin"], t)
  }, blockchainItems[BlockchainType.FILECOIN])), _defineProperty(_byBlockchain2, BlockchainType.BITCOIN, _objectSpread({
    label: prefixTestnet(translate(["utils", "blockchain", "getBlockchainItemBy", "bitcoin"], t)),
    coinName: prefixTestnet(translate(["utils", "blockchain", "getCoinNameBy", "bitcoin"], t)),
    noPrefixLabel: translate(["utils", "blockchain", "getBlockchainItemBy", "bitcoin"], t)
  }, blockchainItems[BlockchainType.BITCOIN])), _defineProperty(_byBlockchain2, BlockchainType.BITCOIN_CASH, _objectSpread({
    label: prefixTestnet(translate(["utils", "blockchain", "getBlockchainItemBy", "bitcoincash"], t)),
    coinName: prefixTestnet(translate(["utils", "blockchain", "getCoinNameBy", "bitcoincash"], t)),
    noPrefixLabel: translate(["utils", "blockchain", "getBlockchainItemBy", "bitcoincash"], t)
  }, blockchainItems[BlockchainType.BITCOIN_CASH])), _defineProperty(_byBlockchain2, BlockchainType.LITECOIN, _objectSpread({
    label: prefixTestnet(translate(["utils", "blockchain", "getBlockchainItemBy", "litecoin"], t)),
    coinName: prefixTestnet(translate(["utils", "blockchain", "getCoinNameBy", "litecoin"], t)),
    noPrefixLabel: translate(["utils", "blockchain", "getBlockchainItemBy", "litecoin"], t)
  }, blockchainItems[BlockchainType.LITECOIN])), _byBlockchain2);
  return byBlockchain[blockchain];
};
export var getBlockchainItemForPDF = function getBlockchainItemForPDF(blockchain, t) {
  var item = getBlockchainItemBy(blockchain, t);
  return _objectSpread(_objectSpread({}, item), {}, {
    label: isTestnetStaging ? item.noPrefixLabel : item.label
  });
};
export var isIgnoreCoinType = function isIgnoreCoinType(coinType) {
  return [getCoinTypeByBlockchain({
    blockchain: BlockchainType.BITCOIN,
    isApplyNFTWalletVersion: false
  }).coinType, getCoinTypeByBlockchain({
    blockchain: BlockchainType.BITCOIN_CASH,
    isApplyNFTWalletVersion: false
  }).coinType, getCoinTypeByBlockchain({
    blockchain: BlockchainType.LITECOIN,
    isApplyNFTWalletVersion: false
  }).coinType, getCoinTypeByBlockchain({
    blockchain: BlockchainType.FILECOIN,
    isApplyNFTWalletVersion: false
  }).coinType].includes(coinType);
};
export var isBitcoinOrBitcoinCashOrLitecoin = function isBitcoinOrBitcoinCashOrLitecoin(blockchain) {
  return [BlockchainType.BITCOIN, BlockchainType.BITCOIN_CASH, BlockchainType.LITECOIN].some(function (targetBlockchain) {
    return targetBlockchain === blockchain;
  });
};
export var toBlockchainUnit = function toBlockchainUnit(blockchain, t) {
  var blockchainItem = getBlockchainItemBy(blockchain, t);
  return blockchainItem.unit;
};
export var getFilecoinBlockTimeSeconds = function getFilecoinBlockTimeSeconds() {
  var FILECOIN_BLOCK_TIME_SECONDS = 30;
  return confirmCount(BlockchainType.FILECOIN) * FILECOIN_BLOCK_TIME_SECONDS;
};
export var confirmCount = function (reactEnv) {
  var _development, _testnet, _testnetStaging, _production, _productionStaging, _confirmCountByEnv;

  var confirmCountByEnv = (_confirmCountByEnv = {
    development: (_development = {}, _defineProperty(_development, BlockchainType.ETHEREUM, 2), _defineProperty(_development, BlockchainType.KLAYTN, 1), _defineProperty(_development, BlockchainType.BINANCE_SMART_CHAIN, 8), _defineProperty(_development, BlockchainType.POLYGON, 30), _defineProperty(_development, BlockchainType.FILECOIN, 10), _defineProperty(_development, BlockchainType.BITCOIN, 2), _defineProperty(_development, BlockchainType.BITCOIN_CASH, 2), _defineProperty(_development, BlockchainType.LITECOIN, 6), _development),
    testnet: (_testnet = {}, _defineProperty(_testnet, BlockchainType.ETHEREUM, 2), _defineProperty(_testnet, BlockchainType.KLAYTN, 1), _defineProperty(_testnet, BlockchainType.BINANCE_SMART_CHAIN, 8), _defineProperty(_testnet, BlockchainType.POLYGON, 30), _defineProperty(_testnet, BlockchainType.FILECOIN, 10), _defineProperty(_testnet, BlockchainType.BITCOIN, 2), _defineProperty(_testnet, BlockchainType.BITCOIN_CASH, 2), _defineProperty(_testnet, BlockchainType.LITECOIN, 6), _testnet)
  }, _defineProperty(_confirmCountByEnv, "testnet-staging", (_testnetStaging = {}, _defineProperty(_testnetStaging, BlockchainType.ETHEREUM, 2), _defineProperty(_testnetStaging, BlockchainType.KLAYTN, 1), _defineProperty(_testnetStaging, BlockchainType.BINANCE_SMART_CHAIN, 8), _defineProperty(_testnetStaging, BlockchainType.POLYGON, 30), _defineProperty(_testnetStaging, BlockchainType.FILECOIN, 10), _defineProperty(_testnetStaging, BlockchainType.BITCOIN, 2), _defineProperty(_testnetStaging, BlockchainType.BITCOIN_CASH, 2), _defineProperty(_testnetStaging, BlockchainType.LITECOIN, 6), _testnetStaging)), _defineProperty(_confirmCountByEnv, "production", (_production = {}, _defineProperty(_production, BlockchainType.ETHEREUM, 12), _defineProperty(_production, BlockchainType.KLAYTN, 1), _defineProperty(_production, BlockchainType.BINANCE_SMART_CHAIN, 16), _defineProperty(_production, BlockchainType.POLYGON, 128), _defineProperty(_production, BlockchainType.FILECOIN, 120), _defineProperty(_production, BlockchainType.BITCOIN, 6), _defineProperty(_production, BlockchainType.BITCOIN_CASH, 15), _defineProperty(_production, BlockchainType.LITECOIN, 6), _production)), _defineProperty(_confirmCountByEnv, "production-staging", (_productionStaging = {}, _defineProperty(_productionStaging, BlockchainType.ETHEREUM, 12), _defineProperty(_productionStaging, BlockchainType.KLAYTN, 1), _defineProperty(_productionStaging, BlockchainType.BINANCE_SMART_CHAIN, 16), _defineProperty(_productionStaging, BlockchainType.POLYGON, 128), _defineProperty(_productionStaging, BlockchainType.FILECOIN, 120), _defineProperty(_productionStaging, BlockchainType.BITCOIN, 6), _defineProperty(_productionStaging, BlockchainType.BITCOIN_CASH, 15), _defineProperty(_productionStaging, BlockchainType.LITECOIN, 6), _productionStaging)), _confirmCountByEnv);
  return function (blockchain) {
    return confirmCountByEnv[reactEnv][blockchain];
  };
}(env.REACT_ENV);
export var confirmCountText = function confirmCountText(blockchain) {
  return String(confirmCount(blockchain));
};
export var makeDepositWarnings = function makeDepositWarnings(params) {
  var _byBlockchain3;

  var isApplyNFT = params.isApplyNFT,
      isNotSupportNFT = params.isNotSupportNFT,
      isApplyNFTWalletVersion = params.isApplyNFTWalletVersion,
      blockchain = params.blockchain,
      t = params.t;

  var _getBlockchainItemBy = getBlockchainItemBy(blockchain, t),
      coinName = _getBlockchainItemBy.coinName;

  var _getCoinTypeByBlockch2 = getCoinTypeByBlockchain({
    isApplyNFTWalletVersion: isApplyNFTWalletVersion,
    blockchain: blockchain
  }),
      coinType = _getCoinTypeByBlockch2.coinType,
      nftCoinType = _getCoinTypeByBlockch2.nftCoinType;

  var ethWarnings = ["".concat(coinName).concat(isIgnoreCoinType(coinType) ? "" : ", ".concat(prefixTestnet(coinType)).concat(translate(["utils", "blockchain", "makeDepositWarnings", "common", "token"], t)).concat(isApplyNFT && !isNotSupportNFT ? ", ".concat(prefixTestnet(nftCoinType !== null && nftCoinType !== void 0 ? nftCoinType : "")).concat(translate(["utils", "blockchain", "makeDepositWarnings", "common", "token"], t)) : "").concat(translate(["utils", "blockchain", "makeDepositWarnings", "ethWarning", "applyNft"], t))).concat(translate(["utils", "blockchain", "makeDepositWarnings", "ethWarning", "line1Prefix"], t)).concat(confirmCountText(blockchain)).concat(translate(["utils", "blockchain", "makeDepositWarnings", "ethWarning", "line1Postfix"], t)), translate(["utils", "blockchain", "makeDepositWarnings", "ethWarning", "line2"], t), translate(["utils", "blockchain", "makeDepositWarnings", "ethWarning", "line3"], t)];
  var klaytnWarnings = [translate(["utils", "blockchain", "makeDepositWarnings", "klaytnWarning", "line1"], t), translate(["utils", "blockchain", "makeDepositWarnings", "klaytnWarning", "line2"], t)];
  var bscWarnings = ["".concat(coinName).concat(isIgnoreCoinType(coinType) ? "" : ", ".concat(prefixTestnet(coinType)).concat(translate(["utils", "blockchain", "makeDepositWarnings", "common", "token"], t)).concat(isApplyNFT && !isNotSupportNFT ? ", ".concat(prefixTestnet(nftCoinType !== null && nftCoinType !== void 0 ? nftCoinType : "")).concat(translate(["utils", "blockchain", "makeDepositWarnings", "common", "token"], t)) : "").concat(translate(["utils", "blockchain", "makeDepositWarnings", "bscWarning", "applyNft"], t))).concat(translate(["utils", "blockchain", "makeDepositWarnings", "bscWarning", "line1Prefix"], t)).concat(confirmCountText(blockchain)).concat(translate(["utils", "blockchain", "makeDepositWarnings", "bscWarning", "line1Postfix"], t)), translate(["utils", "blockchain", "makeDepositWarnings", "bscWarning", "line2"], t), translate(["utils", "blockchain", "makeDepositWarnings", "bscWarning", "line3"], t), translate(["utils", "blockchain", "makeDepositWarnings", "bscWarning", "line4"], t)];
  var polygonWarnings = ["".concat(coinName).concat(isIgnoreCoinType(coinType) ? "" : ", ".concat(prefixTestnet(coinType)).concat(translate(["utils", "blockchain", "makeDepositWarnings", "common", "token"], t)).concat(isApplyNFT && !isNotSupportNFT ? ", ".concat(prefixTestnet(nftCoinType !== null && nftCoinType !== void 0 ? nftCoinType : "")).concat(translate(["utils", "blockchain", "makeDepositWarnings", "common", "token"], t)) : "").concat(translate(["utils", "blockchain", "makeDepositWarnings", "polygonWarning", "applyNft"], t))).concat(translate(["utils", "blockchain", "makeDepositWarnings", "polygonWarning", "line1Prefix"], t)).concat(confirmCountText(blockchain)).concat(translate(["utils", "blockchain", "makeDepositWarnings", "polygonWarning", "line1Postfix"], t)), translate(["utils", "blockchain", "makeDepositWarnings", "polygonWarning", "line2"], t), translate(["utils", "blockchain", "makeDepositWarnings", "polygonWarning", "line3"], t)];
  var byBlockchain = (_byBlockchain3 = {}, _defineProperty(_byBlockchain3, BlockchainType.ETHEREUM, filterEmpty(ethWarnings)), _defineProperty(_byBlockchain3, BlockchainType.KLAYTN, klaytnWarnings), _defineProperty(_byBlockchain3, BlockchainType.BINANCE_SMART_CHAIN, filterEmpty(bscWarnings)), _defineProperty(_byBlockchain3, BlockchainType.POLYGON, filterEmpty(polygonWarnings)), _defineProperty(_byBlockchain3, BlockchainType.FILECOIN, ["".concat(translate(["utils", "blockchain", "makeDepositWarnings", "filecoinWarning", "line1Prefix"], t)).concat(confirmCountText(blockchain)).concat(translate(["utils", "blockchain", "makeDepositWarnings", "filecoinWarning", "line1Postfix"], t)), translate(["utils", "blockchain", "makeDepositWarnings", "filecoinWarning", "line2"], t), translate(["utils", "blockchain", "makeDepositWarnings", "filecoinWarning", "line3"], t)]), _defineProperty(_byBlockchain3, BlockchainType.BITCOIN, ["".concat(translate(["utils", "blockchain", "makeDepositWarnings", "bitcoinWarning", "line1Prefix"], t)).concat(confirmCountText(blockchain)).concat(translate(["utils", "blockchain", "makeDepositWarnings", "bitcoinWarning", "line1Postfix"], t)), translate(["utils", "blockchain", "makeDepositWarnings", "bitcoinWarning", "line2"], t)]), _defineProperty(_byBlockchain3, BlockchainType.BITCOIN_CASH, [// TODO
  "".concat(translate(["utils", "blockchain", "makeDepositWarnings", "bitcoinWarning", "line1Prefix"], t)).concat(confirmCountText(blockchain)).concat(translate(["utils", "blockchain", "makeDepositWarnings", "bitcoinWarning", "line1Postfix"], t)), translate(["utils", "blockchain", "makeDepositWarnings", "bitcoinWarning", "line2"], t)]), _defineProperty(_byBlockchain3, BlockchainType.LITECOIN, ["".concat(translate(["utils", "blockchain", "makeDepositWarnings", "litecoinWarning", "line1Prefix"], t)).concat(confirmCountText(blockchain)).concat(translate(["utils", "blockchain", "makeDepositWarnings", "litecoinWarning", "line1Postfix"], t)), translate(["utils", "blockchain", "makeDepositWarnings", "litecoinWarning", "line2"], t)]), _byBlockchain3);
  return ["".concat(translate(["utils", "blockchain", "makeDepositWarnings", "common", "line1Prefix"], t)).concat(coinName).concat(isIgnoreCoinType(coinType) ? "" : ", ".concat(prefixTestnet(coinType)).concat(translate(["utils", "blockchain", "makeDepositWarnings", "common", "token"], t))).concat(isApplyNFT && !isNotSupportNFT ? ", ".concat(prefixTestnet(nftCoinType !== null && nftCoinType !== void 0 ? nftCoinType : "")).concat(translate(["utils", "blockchain", "makeDepositWarnings", "common", "token"], t)) : "").concat(translate(["utils", "blockchain", "makeDepositWarnings", "common", "line1Postfix"], t), "\n").concat(isApplyNFT && isNotSupportNFT ? "(".concat(translate(["supportTokenMessage"], t, {
    nftCoinType: prefixTestnet(nftCoinType !== null && nftCoinType !== void 0 ? nftCoinType : "")
  }), ")") : "")].concat(_toConsumableArray(byBlockchain[blockchain]));
};
export var makeGasFeeDepositFormWarnings = function makeGasFeeDepositFormWarnings(blockchain, t) {
  var _byBlockchain4;

  var _getBlockchainItemBy2 = getBlockchainItemBy(blockchain, t),
      coinName = _getBlockchainItemBy2.coinName;

  var byBlockchain = (_byBlockchain4 = {}, _defineProperty(_byBlockchain4, BlockchainType.ETHEREUM, ["".concat(translate(["utils", "blockchain", "makeGasFeeDepositFormWarnings", "ethWarning", "line1Prefix"], t)).concat(confirmCountText(blockchain)).concat(translate(["utils", "blockchain", "makeGasFeeDepositFormWarnings", "ethWarning", "line1Postfix"], t))]), _defineProperty(_byBlockchain4, BlockchainType.KLAYTN, ["".concat(translate(["utils", "blockchain", "makeGasFeeDepositFormWarnings", "klaytnWarning", "line1Prefix"], t)).concat(confirmCountText(blockchain)).concat(translate(["utils", "blockchain", "makeGasFeeDepositFormWarnings", "klaytnWarning", "line1Postfix"], t))]), _defineProperty(_byBlockchain4, BlockchainType.BINANCE_SMART_CHAIN, ["".concat(translate(["utils", "blockchain", "makeGasFeeDepositFormWarnings", "ethWarning", "line1Prefix"], t)).concat(confirmCountText(blockchain)).concat(translate(["utils", "blockchain", "makeGasFeeDepositFormWarnings", "ethWarning", "line1Postfix"], t))]), _defineProperty(_byBlockchain4, BlockchainType.POLYGON, ["".concat(translate(["utils", "blockchain", "makeGasFeeDepositFormWarnings", "ethWarning", "line1Prefix"], t)).concat(confirmCountText(blockchain)).concat(translate(["utils", "blockchain", "makeGasFeeDepositFormWarnings", "ethWarning", "line1Postfix"], t))]), _defineProperty(_byBlockchain4, BlockchainType.FILECOIN, ["".concat(translate(["utils", "blockchain", "makeGasFeeDepositFormWarnings", "filecoinWarning", "line1Prefix"], t)).concat(confirmCountText(blockchain)).concat(translate(["utils", "blockchain", "makeGasFeeDepositFormWarnings", "filecoinWarning", "line1Postfix"], t))]), _defineProperty(_byBlockchain4, BlockchainType.BITCOIN, []), _defineProperty(_byBlockchain4, BlockchainType.BITCOIN_CASH, []), _defineProperty(_byBlockchain4, BlockchainType.LITECOIN, []), _byBlockchain4);
  return ["".concat(translate(["utils", "blockchain", "makeGasFeeDepositFormWarnings", "common", "line1Prefix"], t)).concat(coinName).concat(translate(["utils", "blockchain", "makeGasFeeDepositFormWarnings", "common", "line1Postfix"], t))].concat(_toConsumableArray(byBlockchain[blockchain]), [translate(["utils", "blockchain", "makeGasFeeDepositFormWarnings", "common", "line2"], t)]);
};
export var walletNameByBlockchain = function walletNameByBlockchain(_ref) {
  var _byWalletType;

  var isNewWallet = _ref.isNewWallet,
      walletType = _ref.walletType,
      blockchain = _ref.blockchain,
      t = _ref.t;
  var byWalletType = (_byWalletType = {}, _defineProperty(_byWalletType, WalletType.MASTERWALLET, isNewWallet ? translate(["utils", "blockchain", "walletNameByBlockchain", "wallet"], t) : translate(["utils", "blockchain", "walletNameByBlockchain", "masterWallet"], t)), _defineProperty(_byWalletType, WalletType.USERWALLET, translate(["utils", "blockchain", "walletNameByBlockchain", "userWallet"], t)), _byWalletType);
  return isBitcoinOrBitcoinCashOrLitecoin(blockchain) ? translate(["utils", "blockchain", "walletNameByBlockchain", "wallet"], t) : byWalletType[walletType];
};
export var getRegisterCoinBlockchains = function getRegisterCoinBlockchains() {
  return [BlockchainType.ETHEREUM, BlockchainType.KLAYTN, BlockchainType.BINANCE_SMART_CHAIN, BlockchainType.POLYGON];
};
export var isNFTTransferBlockchain = function isNFTTransferBlockchain(targetBlockchain) {
  return [BlockchainType.ETHEREUM, BlockchainType.KLAYTN, BlockchainType.BINANCE_SMART_CHAIN, BlockchainType.POLYGON].some(function (blockchain) {
    return blockchain === targetBlockchain;
  });
};
export var getBlockchainsByLanguage = function getBlockchainsByLanguage() {
  var isKo = i18n.language === "ko";
  var koBlockchains = [BlockchainType.LITECOIN, BlockchainType.BITCOIN, BlockchainType.BITCOIN_CASH, BlockchainType.BINANCE_SMART_CHAIN, BlockchainType.ETHEREUM, BlockchainType.KLAYTN, BlockchainType.POLYGON];
  var enBlockchains = [BlockchainType.BITCOIN, BlockchainType.BITCOIN_CASH, BlockchainType.BINANCE_SMART_CHAIN, BlockchainType.ETHEREUM, BlockchainType.KLAYTN, BlockchainType.LITECOIN, BlockchainType.POLYGON];
  var blockchains = isKo ? koBlockchains : enBlockchains;
  return blockchains;
};