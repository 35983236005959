import _defineProperty from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
import _isNaN from "lodash/isNaN";
import _isEmpty from "lodash/isEmpty";
import BN from "bn.js";
import BigNumber from "bignumber.js";
import { BlockchainType } from "@haechi-labs/henesis-wallet-core/lib/blockchain";
import numeral from "numeral";
import { numToKorean, FormatOptions } from "num-to-korean";
import { TransferType } from "../interfaces/transfer";
import { translate } from "../locales";
import { Currency } from "@haechi-labs/henesis-wallet-core/lib/__generate__/billings";
export var TO_EIGHTEEN_FIXED = 18;
export var TO_EIGHT_FIXED = 8;
var TO_TWO_FIXED = 2;
export var BTC_DECIMAL = 8;
export var BCH_DECIMAL = 8;
export var LTC_DECIMAL = 8;
export var ETH_AND_KLAY_DECIMAL = 18;
export var BSC_DECIMAL = 18;
export var POLYGON_DECIMAL = 18;
export var FILCOIN_DECIMAL = 18;
var NINE_DECIMAL_DIGITS = Math.pow(10, 9);
var GWEI_DIGITS = NINE_DECIMAL_DIGITS;
var BASELINE_DECIMALS = 8;
export var emptyToZero = function emptyToZero(num) {
  return num ? num : "0";
};

var getOverFloatingBySymbol = function getOverFloatingBySymbol(_ref) {
  var _byBlockchain;

  var blockchain = _ref.blockchain,
      decimals = _ref.decimals,
      disabledFixed = _ref.disabledFixed;
  var byBlockchain = (_byBlockchain = {}, _defineProperty(_byBlockchain, BlockchainType.ETHEREUM, ETH_AND_KLAY_DECIMAL), _defineProperty(_byBlockchain, BlockchainType.KLAYTN, ETH_AND_KLAY_DECIMAL), _defineProperty(_byBlockchain, BlockchainType.BINANCE_SMART_CHAIN, BSC_DECIMAL), _defineProperty(_byBlockchain, BlockchainType.POLYGON, POLYGON_DECIMAL), _defineProperty(_byBlockchain, BlockchainType.FILECOIN, FILCOIN_DECIMAL), _defineProperty(_byBlockchain, BlockchainType.BITCOIN, BTC_DECIMAL), _defineProperty(_byBlockchain, BlockchainType.BITCOIN_CASH, BTC_DECIMAL), _defineProperty(_byBlockchain, BlockchainType.LITECOIN, LTC_DECIMAL), _byBlockchain);
  var res = decimals !== null && decimals !== void 0 ? decimals : byBlockchain[blockchain];

  if (disabledFixed) {
    return res;
  }

  return res > BASELINE_DECIMALS ? BASELINE_DECIMALS : res;
};

var getRegexOverFloatingBySymbol = function getRegexOverFloatingBySymbol(_ref2) {
  var blockchain = _ref2.blockchain,
      decimals = _ref2.decimals;
  return new RegExp("(\\.\\d{".concat(getOverFloatingBySymbol({
    blockchain: blockchain,
    decimals: decimals
  }), "})\\d+"), "g");
};

export var getDecimalDigitsByBlockchain = function getDecimalDigitsByBlockchain(_ref3) {
  var _byBlockchain2;

  var blockchain = _ref3.blockchain,
      decimals = _ref3.decimals;
  var byBlockchain = (_byBlockchain2 = {}, _defineProperty(_byBlockchain2, BlockchainType.ETHEREUM, Math.pow(10, ETH_AND_KLAY_DECIMAL)), _defineProperty(_byBlockchain2, BlockchainType.KLAYTN, Math.pow(10, ETH_AND_KLAY_DECIMAL)), _defineProperty(_byBlockchain2, BlockchainType.BINANCE_SMART_CHAIN, Math.pow(10, BSC_DECIMAL)), _defineProperty(_byBlockchain2, BlockchainType.POLYGON, Math.pow(10, POLYGON_DECIMAL)), _defineProperty(_byBlockchain2, BlockchainType.FILECOIN, Math.pow(10, FILCOIN_DECIMAL)), _defineProperty(_byBlockchain2, BlockchainType.BITCOIN, Math.pow(10, BTC_DECIMAL)), _defineProperty(_byBlockchain2, BlockchainType.BITCOIN_CASH, Math.pow(10, BCH_DECIMAL)), _defineProperty(_byBlockchain2, BlockchainType.LITECOIN, Math.pow(10, LTC_DECIMAL)), _byBlockchain2);

  if (decimals !== undefined) {
    return Math.pow(10, decimals);
  }

  return byBlockchain[blockchain];
};
export var amountToMultipleDecimalBN = function amountToMultipleDecimalBN(_ref4) {
  var amount = _ref4.amount,
      blockchain = _ref4.blockchain,
      decimals = _ref4.decimals;
  var bigAmount = toBigNumber(amount).multipliedBy(getDecimalDigitsByBlockchain({
    blockchain: blockchain,
    decimals: decimals
  }));
  return new BN(bigAmount.toFixed(0));
};
export var sumAmountAndEstimatedFee = function sumAmountAndEstimatedFee(_ref5) {
  var amount = _ref5.amount,
      blockchain = _ref5.blockchain,
      estimatedFee = _ref5.estimatedFee;
  var bigNumberEstimatedFee = toBigNumber(emptyToZero(estimatedFee)).dividedBy(getDecimalDigitsByBlockchain({
    blockchain: blockchain
  }));
  return bigNumberEstimatedFee.plus(emptyToZero(amount));
};
export var isNotAvailableAmount = function isNotAvailableAmount(_ref6) {
  var amount = _ref6.amount,
      includeZero = _ref6.includeZero;

  if (amount === "") {
    return true;
  }

  var bigNumberAmount = toBigNumber(emptyToZero(amount));

  if (includeZero) {
    return bigNumberAmount.isNegative();
  }

  return bigNumberAmount.isNegative() || bigNumberAmount.eq(0);
};
export var getOverFloatingAndNotNumber = function getOverFloatingAndNotNumber(_ref7) {
  var amount = _ref7.amount,
      blockchain = _ref7.blockchain,
      t = _ref7.t,
      decimals = _ref7.decimals;
  var regexOverFloating = getRegexOverFloatingBySymbol({
    blockchain: blockchain,
    decimals: decimals
  });
  var targetNumber = amount.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
  var targetAmount = targetNumber.replace(regexOverFloating, "$1");
  var errorMessage = regexOverFloating.test(targetNumber) ? "".concat(translate(["utils", "number", "getOverFloatingAndNotNumber", "regexMessage1"], t)).concat(getOverFloatingBySymbol({
    blockchain: blockchain,
    decimals: decimals
  })).concat(translate(["utils", "number", "getOverFloatingAndNotNumber", "regexMessage2"], t)) : !_isEmpty(amount) && amount !== targetAmount ? translate(["utils", "number", "getOverFloatingAndNotNumber", "regexMessage3"], t) : "";
  return {
    filteredAmount: targetAmount,
    errorMessage: errorMessage
  };
};
export var isLackBalanceAmount = function isLackBalanceAmount(_ref8) {
  var amount = _ref8.amount,
      balanceAmount = _ref8.balanceAmount,
      blockchain = _ref8.blockchain,
      estimatedFee = _ref8.estimatedFee,
      decimals = _ref8.decimals;
  var bigNumberBalanceAmount = toBigNumber(balanceAmount).dividedBy(getDecimalDigitsByBlockchain({
    blockchain: blockchain,
    decimals: decimals
  }));
  return bigNumberBalanceAmount.minus(sumAmountAndEstimatedFee({
    amount: amount,
    blockchain: blockchain,
    estimatedFee: estimatedFee
  })).isLessThan(0);
};
export var numberToCountFormat = function numberToCountFormat(amount) {
  return toBigNumber(amount).toFormat();
};
export var numberToAmountFormat = function numberToAmountFormat(amount) {
  return toBigNumber(amount).toFormat();
};
export var numberToCurrencyAmountFormat = function numberToCurrencyAmountFormat(params) {
  var _byCurrency;

  var amount = params.amount,
      currency = params.currency;
  var byCurrency = (_byCurrency = {}, _defineProperty(_byCurrency, Currency.KRW, "₩"), _defineProperty(_byCurrency, Currency.USD, "USD"), _byCurrency);
  var amountFormat = currency === Currency.KRW ? toBigNumber(amount).toFormat(0, BigNumber.ROUND_HALF_DOWN) : toBigNumber(toBigNumber(amount).toFormat(2, BigNumber.ROUND_HALF_DOWN)).toFormat();
  return "".concat(currency === Currency.KRW ? "".concat(byCurrency[currency], " ") : "").concat(amountFormat).concat(currency === Currency.USD ? " ".concat(byCurrency[currency]) : "");
};
export var numberToCurrencyClosedAmountFormat = function numberToCurrencyClosedAmountFormat(params) {
  var _byCurrency2;

  var amount = params.amount,
      currency = params.currency;
  var byCurrency = (_byCurrency2 = {}, _defineProperty(_byCurrency2, Currency.KRW, "₩"), _defineProperty(_byCurrency2, Currency.USD, "USD"), _byCurrency2);
  var amountFormat = toBigNumber(toBigNumber(amount).toFormat(2, BigNumber.ROUND_HALF_DOWN)).toFormat();
  return "".concat(currency === Currency.KRW ? "".concat(byCurrency[currency], " ") : "").concat(amountFormat).concat(currency === Currency.USD ? " ".concat(byCurrency[currency]) : "");
};
export var numberToBalanceFormat = function numberToBalanceFormat(_ref9) {
  var coin = _ref9.coin,
      blockchain = _ref9.blockchain,
      decimals = _ref9.decimals,
      disabledFixed = _ref9.disabledFixed;
  var res = toBigNumber(coin).dividedBy(getDecimalDigitsByBlockchain({
    blockchain: blockchain,
    decimals: decimals
  })).toFixed(getOverFloatingBySymbol({
    blockchain: blockchain,
    decimals: decimals,
    disabledFixed: disabledFixed
  }), BigNumber.ROUND_DOWN);
  return toBigNumber(res).toFormat();
};
export var numberToBalanceSignFormat = function numberToBalanceSignFormat(_ref10) {
  var coin = _ref10.coin,
      blockchain = _ref10.blockchain,
      transferType = _ref10.transferType,
      decimals = _ref10.decimals;
  var divideDecimalDigitCoin = toBigNumber(coin).dividedBy(getDecimalDigitsByBlockchain({
    blockchain: blockchain,
    decimals: decimals
  })).toFixed(getOverFloatingBySymbol({
    blockchain: blockchain,
    decimals: decimals
  }), BigNumber.ROUND_DOWN);
  var bigNumberCoin = toBigNumber(divideDecimalDigitCoin);
  return "".concat(transferType === TransferType.DEPOSIT ? "+" : "-", " ").concat(bigNumberCoin.toFormat());
};
export var isZeroBalance = function isZeroBalance(_ref11) {
  var coin = _ref11.coin,
      blockchain = _ref11.blockchain,
      decimals = _ref11.decimals;
  var bigNumberBalanceAmount = toBigNumber(coin).dividedBy(getDecimalDigitsByBlockchain({
    blockchain: blockchain,
    decimals: decimals
  }));
  return bigNumberBalanceAmount.eq(toBigNumber(0));
};
export var numberToGasPriceBalanceFormat = function numberToGasPriceBalanceFormat(_ref12) {
  var gasPriceBalance = _ref12.gasPriceBalance,
      blockchain = _ref12.blockchain,
      _ref12$fixed = _ref12.fixed,
      fixed = _ref12$fixed === void 0 ? TO_TWO_FIXED : _ref12$fixed,
      ignoreFixed = _ref12.ignoreFixed;
  var res = toBigNumber(gasPriceBalance).dividedBy(getDecimalDigitsByBlockchain({
    blockchain: blockchain
  }));

  if (ignoreFixed) {
    return res.toFormat();
  }

  return toBigNumber(res.toFixed(fixed, BigNumber.ROUND_DOWN)).toFormat();
};
export var numberToGasPriceBalanceSignFormat = function numberToGasPriceBalanceSignFormat(_ref13) {
  var gasPriceBalance = _ref13.gasPriceBalance,
      blockchain = _ref13.blockchain,
      _ref13$fixed = _ref13.fixed,
      fixed = _ref13$fixed === void 0 ? TO_TWO_FIXED : _ref13$fixed,
      ignoreFixed = _ref13.ignoreFixed,
      transferType = _ref13.transferType;
  return "".concat(transferType === TransferType.DEPOSIT ? "+" : "-", " ").concat(numberToGasPriceBalanceFormat({
    gasPriceBalance: gasPriceBalance,
    blockchain: blockchain,
    fixed: fixed,
    ignoreFixed: ignoreFixed
  }));
};
export var numberToGasPriceBalanceSignFormatWithoutMeaninglessZero = function numberToGasPriceBalanceSignFormatWithoutMeaninglessZero(params) {
  var gasPriceBalanceSignFormat = numberToGasPriceBalanceSignFormat(params);
  return gasPriceBalanceSignFormat === "- 0" ? "-" : gasPriceBalanceSignFormat;
};
export var numberToGasPriceFormat = function numberToGasPriceFormat(_ref14) {
  var gasPrice = _ref14.gasPrice,
      _ref14$fixed = _ref14.fixed,
      fixed = _ref14$fixed === void 0 ? TO_TWO_FIXED : _ref14$fixed;
  var res = toBigNumber(gasPrice).dividedBy(GWEI_DIGITS).toFixed(fixed, BigNumber.ROUND_DOWN);
  return toBigNumber(res).toFormat();
};
export var isDiffTenPercentageGasPrice = function isDiffTenPercentageGasPrice(_ref15) {
  var gasPrice = _ref15.gasPrice,
      currentGasPrice = _ref15.currentGasPrice;
  var bigNumberTenPercentageGasPrice = toBigNumber(gasPrice).plus(toBigNumber(gasPrice).multipliedBy(0.1));
  var bigNumberCurrentGasPrice = toBigNumber(currentGasPrice);
  return bigNumberCurrentGasPrice.minus(bigNumberTenPercentageGasPrice).isGreaterThanOrEqualTo(0);
};
export var toBigNumber = function toBigNumber(amount) {
  var target = new BigNumber(String(amount));

  if (_isNaN(target.toNumber())) {
    return new BigNumber(numeral(amount).value());
  }

  return target;
};
export var plusAmount = function plusAmount(amount1, amount2) {
  return toBigNumber(amount1).plus(toBigNumber(amount2));
};
export var minusAmount = function minusAmount(amount1, amount2) {
  return toBigNumber(amount1).minus(toBigNumber(amount2));
};
export var positiveAmount = function positiveAmount(amount) {
  return toBigNumber(amount).isGreaterThanOrEqualTo(0) ? toBigNumber(amount) : toBigNumber(0);
};
export var amountToHangulFormat = function amountToHangulFormat(amount) {
  var moreThanThousand = toBigNumber(amount).dividedToIntegerBy(10000).multipliedBy(10000).integerValue();
  var lessThanThousand = toBigNumber(amount).minus(moreThanThousand);
  var isBothAmount = moreThanThousand.isGreaterThan(0) && lessThanThousand.isGreaterThan(0);
  return "".concat(moreThanThousand.isGreaterThan(0) ? numToKorean(moreThanThousand.toNumber(), FormatOptions.MIXED) : "").concat(isBothAmount ? " " : "").concat(lessThanThousand.isGreaterThan(0) ? numberToAmountFormat(lessThanThousand) : "");
};
export var getBalanceAmount = function getBalanceAmount(_ref16) {
  var _balance$spendableAmo;

  var balance = _ref16.balance;
  return String((_balance$spendableAmo = balance === null || balance === void 0 ? void 0 : balance.spendableAmount) !== null && _balance$spendableAmo !== void 0 ? _balance$spendableAmo : "0");
};