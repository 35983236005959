/*tslint:disable*/
export var zIndex = Object.freeze({
  lowest: -1,
  veryLow: 0,
  low: 1,
  middle: 10,
  high: 11,
  veryHigh: 999,
  veryHigh1: 1000,
  highest: 1301
});