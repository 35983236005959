import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import * as React from "react";

function SvgComponent(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none"
  }, props), __jsx("path", {
    d: "M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z",
    fill: "#8433DB"
  }), __jsx("path", {
    d: "M15.3577 9.2937C15.0836 9.12695 14.7311 9.12695 14.4178 9.2937L12.2245 10.6693L10.7363 11.5448L8.58225 12.9204C8.30809 13.0872 7.95561 13.0872 7.6423 12.9204L5.95822 11.8366C5.68407 11.6698 5.48825 11.3363 5.48825 10.9612V8.87683C5.48825 8.54334 5.64491 8.20985 5.95822 8.00142L7.6423 6.95926C7.91645 6.79251 8.26893 6.79251 8.58225 6.95926L10.2663 8.04311C10.5405 8.20985 10.7363 8.54334 10.7363 8.91852V10.2942L12.2245 9.37707V7.95973C12.2245 7.62624 12.0679 7.29275 11.7546 7.08432L8.62141 5.12506C8.34726 4.95831 7.99478 4.95831 7.68146 5.12506L4.46997 7.12601C4.15666 7.29275 4 7.62624 4 7.95973V11.8783C4 12.2117 4.15666 12.5452 4.46997 12.7537L7.6423 14.7129C7.91645 14.8797 8.26893 14.8797 8.58225 14.7129L10.7363 13.379L12.2245 12.4619L14.3786 11.1279C14.6527 10.9612 15.0052 10.9612 15.3185 11.1279L17.0026 12.1701C17.2768 12.3368 17.4726 12.6703 17.4726 13.0455V15.1298C17.4726 15.4633 17.3159 15.7968 17.0026 16.0052L15.3577 17.0474C15.0836 17.2141 14.7311 17.2141 14.4178 17.0474L12.7337 16.0052C12.4595 15.8385 12.2637 15.505 12.2637 15.1298V13.7958L10.7755 14.7129V16.0886C10.7755 16.4221 10.9321 16.7556 11.2454 16.964L14.4178 18.9233C14.6919 19.09 15.0444 19.09 15.3577 18.9233L18.53 16.964C18.8042 16.7972 19 16.4638 19 16.0886V12.1284C19 11.7949 18.8433 11.4614 18.53 11.253L15.3577 9.2937Z",
    fill: "white"
  }));
}

export default SvgComponent;