export var expiredTimestampFromToken = function expiredTimestampFromToken(token) {
  try {
    return JSON.parse(atob(token.split(".")[1])).exp * 1000;
  } catch (e) {
    return null;
  }
};
export var issuedTimestampFromToken = function issuedTimestampFromToken(token) {
  try {
    return JSON.parse(atob(token.split(".")[1])).iat * 1000;
  } catch (e) {
    return null;
  }
};