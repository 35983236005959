import _values from "lodash/values";
import { BlockchainType } from "@haechi-labs/henesis-wallet-core/lib/blockchain";
export var testHttp = function testHttp(test) {
  return /^http?:\/\//g.test(test);
};
export var testHangul = function testHangul(test) {
  return /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g.test(test);
};
export var testWalletAndAddressName = function testWalletAndAddressName(name) {
  var testCharactersOver1Below30 = /^.{1,30}$/;
  return {
    hasCharactersOver1Below30: testCharactersOver1Below30.test(name)
  };
};
export var isInvalidWalletAndDepositAddressName = function isInvalidWalletAndDepositAddressName(name) {
  return _values(testWalletAndAddressName(name)).some(function (hasRule) {
    return !Boolean(hasRule);
  });
}; // reference https://stackoverflow.com/questions/4460586/javascript-regular-expression-to-check-for-ip-addresses

export var testIpAddress = function testIpAddress(test) {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(test);
};
export var testAddress = function testAddress(test) {
  return /^[A-Za-z0-9]*$/g.test(test);
};
export var testAddressByBlockchain = function testAddressByBlockchain(params) {
  var test = params.test,
      blockchain = params.blockchain;
  var isIgnoreBlockchains = [BlockchainType.BITCOIN_CASH].some(function (targetBlockchain) {
    return targetBlockchain === blockchain;
  });

  if (isIgnoreBlockchains) {
    return true;
  }

  return testAddress(test);
};
export var testOTP = function testOTP(test) {
  return /^[0-9]{0,6}$/g.test(test);
};
export var testEmail = function testEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export var testPassword = function testPassword(password) {
  var testCharactersOver8Below128 = /^.{8,128}$/;
  var testCapitalLetter = /[A-Z]+/;
  var testSmallLetter = /[a-z]+/;
  var testNumber = /[0-9]+/;
  var testSpecialChar = /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/;
  return {
    hasCharactersOver8Below128: testCharactersOver8Below128.test(password),
    hasAlphabet: testCapitalLetter.test(password) || testSmallLetter.test(password),
    hasNumber: testNumber.test(password),
    hasSpecialChar: testSpecialChar.test(password)
  };
};
export var isInvalidPassword = function isInvalidPassword(password) {
  return _values(testPassword(password)).some(function (hasRule) {
    return !Boolean(hasRule);
  });
};