import _defineProperty from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* eslint-disable @typescript-eslint/no-explicit-any */
export function createInjectDecorator(func) {
  return function InjectFunc(__, propName, descriptor) {
    var isArrowFunction = !!descriptor.initializer;

    if (isArrowFunction) {
      return injectFuncInArrow(propName, descriptor, func);
    }

    return injectFuncInMethod(propName, descriptor, func);
  };
}

function injectFuncInArrow(propName, descriptor, func) {
  function initializerProp($this) {
    return function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      func($this.props, $this.state, args);
      var result = descriptor.initializer.apply($this).apply(void 0, args);
      return result;
    };
  }

  return _objectSpread(_objectSpread({}, descriptor), {}, {
    initializer: function initializer() {
      return this[propName] = initializerProp(this);
    }
  });
}

function injectFuncInMethod(propsName, descriptor, func) {
  return _objectSpread(_objectSpread({}, descriptor), {}, {
    value: function value() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      func(this.props, this.state, args);
      var result = descriptor.value.apply(this, args);
      return result;
    }
  });
}