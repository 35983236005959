import _round from "lodash/round";
import _floor from "lodash/floor";
import jwtDecode from "jwt-decode";
import moment from "moment/moment";
import momentTimezone from "moment-timezone";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import "dayjs/locale/en";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import { i18n } from "../libs/i18n";
import { translateByLanguage } from "../locales";
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale("ko", {
  relativeTime: {
    future: "%s 후",
    past: "%s 전",
    s: "%d초",
    m: "1분",
    mm: "%d분",
    h: "1시간",
    hh: "%d시간",
    d: "1일",
    dd: "%d일",
    M: "1달",
    MM: "%d달",
    y: "1년",
    yy: "%d년"
  }
});
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "%ds",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years"
  }
});
export var DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm:ss";
export var DATE_TIME_FORMAT1 = "YYYY.MM.DD HH:mm:ss a";
export var DATE_TIME_FORMAT2 = "YYYY년 MM월 DD일 HH:mm:ss a";
export var DATE_TIME_FORMAT3 = "YYYY/MM/DD";
export var DATE_TIME_FORMAT4 = "YYYY년 MM월 DD일 (UTCZ)";
export var DATE_TIME_FORMAT_INCLUDE_TIME_ZONE = "YYYY/MM/DD HH:mm:ss (UTCZ)";
export var MAXIMUM_TIMESTAMP = 8640000000000000;
export var parseDateTime = function parseDateTime(dateTime, format) {
  return moment(dateTime, format);
};
export var changeDateTimeLocale = function changeDateTimeLocale(language) {
  moment.locale(language);
  dayjs.locale(language);
};
export var toDateTimeText = function toDateTimeText(datetime) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DATE_TIME_FORMAT;
  return moment(datetime).format(format);
};
export var today = function today() {
  return moment();
};
export var todayFormat = function todayFormat() {
  return toDateTimeText(moment(), DATE_TIME_FORMAT1);
};
export var todayTime = function todayTime() {
  return new Date().getTime();
};
export var fromNow = function fromNow(datetime) {
  return dayjs(datetime).fromNow();
};
export var diffDays = function diffDays(date1, date2) {
  return dayjs(date1).diff(date2, "day");
};
export var diffMilliseconds = function diffMilliseconds(date1, date2) {
  return dayjs(date1).diff(date2);
};
export var toMSText = function toMSText(seconds) {
  var minutes = _floor(seconds / 60);

  var remainSeconds = _round(seconds % 60);

  return "".concat(minutes).concat(translateByLanguage(["minutes"], i18n.language), " ").concat(remainSeconds).concat(translateByLanguage(["seconds"], i18n.language));
};
export var getRemainExpiredSeconds = function getRemainExpiredSeconds(apiAccessToken) {
  if (!apiAccessToken) {
    return 0;
  }

  var _jwtDecode = jwtDecode(apiAccessToken),
      _jwtDecode$exp = _jwtDecode.exp,
      exp = _jwtDecode$exp === void 0 ? 0 : _jwtDecode$exp;

  var expiredAt = exp * 1000;
  var remainExpiredSeconds = (expiredAt - today().valueOf()) / 1000;
  return remainExpiredSeconds;
};
export var isApiAccessTokenExpired = function isApiAccessTokenExpired(apiAccessToken) {
  if (!apiAccessToken) {
    return true;
  }

  try {
    return getRemainExpiredSeconds(apiAccessToken) <= 0;
  } catch (error) {
    return true;
  }
};
export var toStartMonth = function toStartMonth(_ref) {
  var year = _ref.year,
      month = _ref.month;
  var date = moment().year(year).month(month);
  return date.startOf("month").startOf("day").startOf("hour").startOf("minute");
};
export var getUtcOffsetString = function getUtcOffsetString() {
  var utfOffset = moment().utcOffset();
  var abs = Math.abs(utfOffset);
  var hours = Math.trunc(abs / 60);
  var minutes = abs % 60;
  return "(UTC".concat(utfOffset < 0 ? "-" : "+").concat(hours < 10 ? "0" : "").concat(hours, ":").concat(minutes < 10 ? "0" : "").concat(minutes, ")");
};
export var toStartDate = function toStartDate(date) {
  return date.startOf("hours").startOf("minutes").startOf("seconds").startOf("milliseconds");
};
export var toEndMonth = function toEndMonth(_ref2) {
  var year = _ref2.year,
      month = _ref2.month;
  var date = moment().year(year).month(month);
  return date.endOf("month").endOf("day").endOf("hour").endOf("minute");
};
export var toEndDate = function toEndDate(date) {
  return date.endOf("hours").endOf("minutes").endOf("seconds").endOf("milliseconds");
};
export var toCurrentTimezoneDate = function toCurrentTimezoneDate(datetime) {
  return momentTimezone(datetime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
};
export var currentTimeStamp = function currentTimeStamp() {
  return moment().unix() * 1000;
};
export var cutToMaximumTimestamp = function cutToMaximumTimestamp(timestamp) {
  return timestamp > MAXIMUM_TIMESTAMP ? MAXIMUM_TIMESTAMP : timestamp;
};