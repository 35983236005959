import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import * as React from "react";

function SvgComponent(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none"
  }, props), __jsx("path", {
    d: "M0 4a4 4 0 014-4h16a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4z",
    fill: "#345D9D"
  }), __jsx("path", {
    d: "M12.17 12.339l-1.042 3.51h5.57a.282.282 0 01.29.272v.092l-.485 1.67a.36.36 0 01-.363.267H7.617l1.429-4.867-1.598.485.363-1.114 1.598-.485 2.01-6.828a.366.366 0 01.363-.266h2.155a.281.281 0 01.29.271v.092l-1.694 5.763 1.598-.484-.34 1.162-1.622.46z",
    fill: "#fff"
  }));
}

export default SvgComponent;