var __jsx = React.createElement;
import React from "react";
import { makeStyles } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LinearProgress } from "@material-ui/core";
export var CIRCULAR_PROGRESS_HEIGHT = "48px";
var useStyles = makeStyles({
  root: {
    padding: "0px",
    margin: "0px"
  },
  colorPrimary: function colorPrimary(props) {
    return {
      color: props.color
    };
  },
  colorSecondary: {
    color: "#FFFFFF"
  },
  bar: function bar(props) {
    return {
      backgroundColor: props.color
    };
  },
  barColorPrimary: function barColorPrimary(props) {
    return {
      backgroundColor: props.color
    };
  }
});

var Progress = function Progress(props) {
  var className = props.className,
      _props$type = props.type,
      type = _props$type === void 0 ? "circular" : _props$type,
      _props$size = props.size,
      size = _props$size === void 0 ? "large" : _props$size;
  var styles = useStyles(props);
  var sizeMap = {
    large: "42px",
    small: "20px"
  };

  switch (type) {
    case "linear":
      return __jsx(LinearProgress, {
        className: className,
        classes: {
          colorPrimary: styles.bar,
          barColorPrimary: styles.barColorPrimary
        }
      });

    case "circular":
    default:
      return __jsx(CircularProgress, {
        className: className,
        classes: {
          colorPrimary: styles.colorPrimary,
          colorSecondary: styles.colorPrimary
        },
        thickness: size === "small" ? 7 : 5.5,
        size: ["small", "large"].some(function (sizeType) {
          return sizeType === size;
        }) ? sizeMap[size] : size
      });
  }
};

export default Progress;