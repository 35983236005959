import _defineProperty from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { unstable_createMuiStrictModeTheme as createTheme } from "@material-ui/core/styles";
import typography from "./typography";
import spacing from "./spacing";
import { palette } from "./palette";
var theme = createTheme({
  typography: typography,
  spacing: spacing,
  zIndex: {
    appBar: 10,
    drawer: 100
  },
  overrides: {
    MuiPopover: {
      root: {
        marginTop: 8
      }
    },
    MuiPickersYear: {
      year: _objectSpread(_objectSpread({}, typography.body2Medium), {}, {
        color: palette.darkgrey.main
      }),
      yearSelected: _objectSpread(_objectSpread({}, typography.h4), {}, {
        color: palette.primary.main
      })
    },
    MuiPickersMonth: {
      month: _objectSpread(_objectSpread({}, typography.body2Medium), {}, {
        color: palette.darkgrey.main
      }),
      monthSelected: _objectSpread(_objectSpread({}, typography.h4), {}, {
        color: palette.primary.main
      })
    }
  }
});
export default theme;