import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from "react";
import RowLabelValueCard from "./RowLabelValueCard";
import { translateComponent, translate } from "../../locales";
import { withTranslation } from "../../libs/i18n";
import { Role } from "@haechi-labs/henesis-wallet-core/lib/accounts";

var WalletPolicyCard = function WalletPolicyCard(props) {
  var t = props.t,
      role = props.role;
  return __jsx(RowLabelValueCard, _extends({}, props, {
    submitText: role === Role.ADMIN ? translateComponent(["walletPolicyCard", "title"], t) : translate(["seeMore"], t)
  }));
};

export default withTranslation(["common", "component"])(WalletPolicyCard);