import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import * as React from "react";

function SvgComponent(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M0 4a4 4 0 014-4h16a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4z",
    fill: "#8DC351"
  }), __jsx("path", {
    d: "M16.184 7.545c-.664-1.507-2.191-1.829-4.06-1.517l-.6-2.328-1.416.366.59 2.321c-.372.094-.755.175-1.134.282l-.59-2.308-1.416.366.6 2.328c-.305.087-2.858.742-2.858.742l.389 1.516s1.04-.292 1.03-.268c.577-.151.849.137.976.409l1.651 6.381c.02.185-.013.5-.41.608.024.013-1.03.265-1.03.265l.155 1.768s2.53-.648 2.862-.731l.607 2.355 1.416-.366-.607-2.372c.39-.09.768-.184 1.137-.282l.604 2.359 1.416-.366-.607-2.352c2.18-.53 3.72-1.905 3.405-4.01-.201-1.267-1.587-2.307-2.738-2.425.708-.627 1.067-1.543.628-2.741zm-.681 5.553c.282 2.083-2.614 2.338-3.57 2.59L11.1 12.57c.96-.252 3.925-1.309 4.402.527zm-1.745-4.245c.299 1.853-2.177 2.067-2.976 2.272l-.758-2.828c.802-.198 3.127-1.158 3.734.556z",
    fill: "#fff"
  }));
}

export default SvgComponent;