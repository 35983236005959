import _defaultTo from "lodash/defaultTo";
import getConfig from "next/config";
import NextI18Next from "next-i18next";
import path from "path";

var _defaultTo2 = _defaultTo(getConfig(), {
  publicRuntimeConfig: {}
}),
    publicRuntimeConfig = _defaultTo2.publicRuntimeConfig;

var _ref = publicRuntimeConfig !== null && publicRuntimeConfig !== void 0 ? publicRuntimeConfig : {
  localeSubpaths: {}
},
    localeSubpaths = _ref.localeSubpaths;

var i18nNext = new NextI18Next({
  defaultLanguage: "ko",
  otherLanguages: ["ko", "en"],
  localeSubpaths: localeSubpaths,
  localePath: path.resolve("./public/static/locales"),
  strictMode: false
});
var i18n = i18nNext.i18n; // i18n.use(Locize).init({
//   backend: {
//     projectId: "b0de0647-e61e-45d4-a0c5-dbaf631e0266",
//     apiKey: "155bd273-4261-44ca-9a72-64362b2eef51",
//     referenceLng: "ko",
//   },
// });

var t = i18n.t.bind(i18n);
export { t, i18n };
var Trans = i18nNext.Trans,
    Link = i18nNext.Link,
    Router = i18nNext.Router,
    initPromise = i18nNext.initPromise,
    config = i18nNext.config,
    useTranslation = i18nNext.useTranslation,
    withTranslation = i18nNext.withTranslation,
    appWithTranslation = i18nNext.appWithTranslation;
export { Trans, Link, Router, initPromise, config, useTranslation, withTranslation, appWithTranslation };
export default i18nNext;