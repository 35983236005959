import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from "react";
import { Role } from "@haechi-labs/henesis-wallet-core/lib/accounts";
import EmptyStates from "./EmptyStates";
import { translateComponent } from "../../locales";
import { withTranslation } from "../../libs/i18n";
import { isBitcoinOrBitcoinCashOrLitecoin } from "../../utils/blockchain";

var WithdrawPolicyEmptyStates = function WithdrawPolicyEmptyStates(props) {
  var role = props.role,
      blockchain = props.blockchain,
      onCreatePolicy = props.onCreatePolicy,
      t = props.t;
  var isAdmin = role === Role.ADMIN;
  return __jsx(EmptyStates, _extends({
    title: translateComponent(["withdrawPolicyEmptyStates", "title"], t),
    content: !isBitcoinOrBitcoinCashOrLitecoin(blockchain) ? translateComponent(["withdrawPolicyEmptyStates", "description"], t) : translateComponent(["withdrawPolicyEmptyStates", "descriptionForBitcoin"], t)
  }, !isAdmin ? {} : {
    buttonText: translateComponent(["withdrawPolicyEmptyStates", "submitButton"], t),
    buttonProps: {
      onClick: onCreatePolicy
    }
  }));
};

export default withTranslation()(WithdrawPolicyEmptyStates);