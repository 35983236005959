var __jsx = React.createElement;
import React from "react";
import EmptyStates from "./EmptyStates";
import { translateComponent } from "../../locales";
import { withTranslation } from "../../libs/i18n";

var GasFeeUsageEmptyStates = function GasFeeUsageEmptyStates(props) {
  var t = props.t;
  return __jsx(EmptyStates, {
    title: translateComponent(["gasFeeUsageEmptyStates", "title"], t),
    content: translateComponent(["gasFeeUsageEmptyStates", "description"], t)
  });
};

export default withTranslation(["common", "component"])(GasFeeUsageEmptyStates);