import _defineProperty from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
import { TransactionType } from "@haechi-labs/henesis-wallet-core/lib/__generate__/eth";
export var transformTransferType = function transformTransferType(transferType) {
  var byType = {
    DEPOSIT: TransferType.DEPOSIT,
    WITHDRAWAL: TransferType.WITHDRAWAL
  };
  return byType[transferType];
};
export var transformTransferTypeForTransactionType = function transformTransferTypeForTransactionType(transactionType) {
  var _byType;

  var byType = (_byType = {}, _defineProperty(_byType, TransactionType.WITHDRAWAL, TransferType.WITHDRAWAL), _defineProperty(_byType, TransactionType.DEPOSIT, TransferType.DEPOSIT), _defineProperty(_byType, TransactionType.UNKNOWNEXTERNALCALL, TransferType.WITHDRAWAL), _defineProperty(_byType, TransactionType.SMARTCONTRACTCALL, TransferType.WITHDRAWAL), _defineProperty(_byType, TransactionType.MASTERWALLETDEPLOYMENT, TransferType.WITHDRAWAL), _defineProperty(_byType, TransactionType.USERWALLETDEPLOYMENT, TransferType.WITHDRAWAL), _defineProperty(_byType, TransactionType.FLUSH, TransferType.WITHDRAWAL), _defineProperty(_byType, TransactionType.NFTWITHDRAWAL, TransferType.WITHDRAWAL), _defineProperty(_byType, TransactionType.HOPTRANSACTION, TransferType.WITHDRAWAL), _defineProperty(_byType, TransactionType.NFTFLUSH, TransferType.WITHDRAWAL), _defineProperty(_byType, TransactionType.FEEWALLETWITHDRAWAL, TransferType.WITHDRAWAL), _byType);
  return byType[transactionType];
};
export var TransferType;

(function (TransferType) {
  TransferType["WITHDRAWAL"] = "WITHDRAWAL";
  TransferType["DEPOSIT"] = "DEPOSIT";
})(TransferType || (TransferType = {}));

export var TransferTransactionStatus;

(function (TransferTransactionStatus) {
  TransferTransactionStatus["PENDINGAPPROVAL"] = "PENDING_APPROVAL";
  TransferTransactionStatus["REJECTED"] = "REJECTED";
  TransferTransactionStatus["REQUESTED"] = "REQUESTED";
  TransferTransactionStatus["PENDING"] = "PENDING";
  TransferTransactionStatus["FAILED"] = "FAILED";
  TransferTransactionStatus["MINED"] = "MINED";
  TransferTransactionStatus["REVERTED"] = "REVERTED";
  TransferTransactionStatus["CONFIRMED"] = "CONFIRMED";
  TransferTransactionStatus["REPLACED"] = "REPLACED";
  TransferTransactionStatus["INTERNALREVERTED"] = "INTERNAL_REVERTED";
})(TransferTransactionStatus || (TransferTransactionStatus = {}));

export var transformTransferStatus = function transformTransferStatus(transactionStatus) {
  var map = {
    PENDING_APPROVAL: TransferTransactionStatus.PENDINGAPPROVAL,
    REJECTED: TransferTransactionStatus.REJECTED,
    REQUESTED: TransferTransactionStatus.REQUESTED,
    PENDING: TransferTransactionStatus.PENDING,
    FAILED: TransferTransactionStatus.FAILED,
    MINED: TransferTransactionStatus.MINED,
    REVERTED: TransferTransactionStatus.REVERTED,
    CONFIRMED: TransferTransactionStatus.CONFIRMED,
    REPLACED: TransferTransactionStatus.REPLACED,
    INTERNAL_REVERTED: TransferTransactionStatus.INTERNALREVERTED
  };
  return map[transactionStatus];
};