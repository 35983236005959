import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["message", "onClose", "t"];
var __jsx = React.createElement;
import React from "react";
import Snackbar from "./Snackbar";
import MaterialButton from "../Button/MaterialButton";
import { translate } from "../../locales";
import { withTranslation } from "../../libs/i18n";

var CloseSnackbar = function CloseSnackbar(props) {
  var message = props.message,
      onClose = props.onClose,
      t = props.t,
      rest = _objectWithoutProperties(props, _excluded);

  return __jsx(Snackbar, _extends({}, rest, {
    message: message,
    action: __jsx(MaterialButton, {
      variant: "link_highlight",
      color: "lightgrey",
      size: "large",
      onClick: onClose
    }, translate(["close"], t))
  }));
};

export default withTranslation(["common", "component"])(CloseSnackbar);