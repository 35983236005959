import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from "react";
import { Role } from "@haechi-labs/henesis-wallet-core/lib/accounts";
import EmptyStates from "./EmptyStates";
import MaterialIcon from "../Icon/MaterialIcon";
import { translateComponent } from "../../locales";
import { withTranslation } from "../../libs/i18n";

var WalletEmptyStates = function WalletEmptyStates(props) {
  var role = props.role,
      onCreate = props.onCreate,
      t = props.t;
  var isViewer = role === Role.VIEWER;
  return __jsx(EmptyStates, _extends({
    title: translateComponent(["walletEmptyStates", "title"], t),
    content: isViewer ? translateComponent(["walletEmptyStates", "descriptionForViewer"], t) : translateComponent(["walletEmptyStates", "description"], t)
  }, isViewer ? {} : {
    buttonProps: {
      startIcon: __jsx(MaterialIcon, {
        name: "account_balance_wallet"
      }),
      onClick: onCreate
    },
    buttonText: translateComponent(["walletEmptyStates", "submitButton"], t)
  }));
};

export default withTranslation()(WalletEmptyStates);