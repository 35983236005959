import { UAParser } from "ua-parser-js";
import { isNodeDevelopment } from "../libs/env";
export var isBrowser = typeof window !== "undefined";
export var getBrowser = function getBrowser() {
  var parser = new UAParser();
  return "".concat(parser.getBrowser().name, " ").concat(parser.getBrowser().version);
};
export var setBrowserInterval = function setBrowserInterval(handler, timeout) {
  if (!isBrowser || isNodeDevelopment) {
    return;
  }

  var intervalId = setInterval(handler, timeout);
  return intervalId;
};