import _defineProperty from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

export var koFontFamily = "Noto Sans KR";
var enFontFamily = "Inter";
var defaultFontFamily = "apple sd gothic neo, 맑은 고딕, malgun gothic, sans-serif";
var FontFamily = "".concat(enFontFamily, ", ").concat(koFontFamily, ", ").concat(defaultFontFamily);
var sourceFontFamily = "Source Code Pro"; // 700 Bold, 400 Normal

var defaultTypography = {
  h1: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "48px",
    lineHeight: "70px",
    letterSpacing: "-0.01em"
  },
  h2: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "34px",
    lineHeight: "50px",
    letterSpacing: "0px"
  },
  h3: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "36px",
    letterSpacing: "0px"
  },
  h4: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0px"
  },
  h5: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.001em"
  },
  h6: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.005em"
  },
  body1: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.001em"
  },
  body2: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.005em"
  },
  body3: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "19px",
    letterSpacing: "0.005em"
  },
  caption: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.01em"
  },
  button: _objectSpread({
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "13px",
    lineHeight: "20px",
    letterSpacing: "0.04em"
  }, {
    textTransform: "unset" // eslint-disable-next-line @typescript-eslint/no-explicit-any

  }),
  button2: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "0.04em"
  },
  button3: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "11px",
    lineHeight: "20px",
    letterSpacing: "0.04em"
  },
  overline: {
    fontFamily: FontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "16px",
    letterSpacing: "0.015em"
  }
};

var typography = _objectSpread({
  code: {
    fontFamily: sourceFontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "19px",
    letterSpacing: "-0.25px"
  },
  body1Medium: _objectSpread(_objectSpread({}, defaultTypography.body1), {}, {
    fontWeight: 500
  }),
  body1Bold: _objectSpread(_objectSpread({}, defaultTypography.body1), {}, {
    fontWeight: 700
  }),
  body2Medium: _objectSpread(_objectSpread({}, defaultTypography.body2), {}, {
    fontWeight: 500
  }),
  body2Bold: _objectSpread(_objectSpread({}, defaultTypography.body2), {}, {
    fontWeight: 700
  }),
  body3Medium: _objectSpread(_objectSpread({}, defaultTypography.body3), {}, {
    fontWeight: 500
  }),
  body3Bold: _objectSpread(_objectSpread({}, defaultTypography.body3), {}, {
    fontWeight: 700
  }),
  captionMedium: _objectSpread(_objectSpread({}, defaultTypography.caption), {}, {
    fontWeight: 500
  }),
  captionBold: _objectSpread(_objectSpread({}, defaultTypography.caption), {}, {
    fontWeight: 700
  }),
  overlineMedium: _objectSpread(_objectSpread({}, defaultTypography.overline), {}, {
    fontWeight: 500
  }),
  overlineBold: _objectSpread(_objectSpread({}, defaultTypography.overline), {}, {
    fontWeight: 700
  })
}, defaultTypography);

export var getTypographyForPDF = function getTypographyForPDF(language) {
  var isKo = language === "ko";
  var typeographyCommonForPDF = {
    title: {
      fontFamily: "SpoqaHanSansNeo-Bold",
      fontSize: 14,
      lineHeight: 1.5
    },
    subTitle: {
      fontFamily: "SpoqaHanSansNeo-Bold",
      fontSize: 10,
      lineHeight: 1.5
    },
    subTitle2: {
      fontFamily: "SpoqaHanSansNeo-Bold",
      fontSize: 8,
      lineHeight: 1.5
    },
    tableBody1: isKo ? {
      fontFamily: "SpoqaHanSansNeo-Regular",
      fontSize: 10,
      lineHeight: 1.5
    } : {
      fontFamily: "SpoqaHanSansNeo-Regular",
      fontSize: 8,
      lineHeight: 1.5
    },
    tableBody2: {
      fontFamily: "SpoqaHanSansNeo-Regular",
      fontSize: 8,
      lineHeight: 1.5
    },
    tableH1: isKo ? {
      fontFamily: "SpoqaHanSansNeo-Bold",
      fontSize: 10,
      lineHeight: 1.5
    } : {
      fontFamily: "SpoqaHanSansNeo-Bold",
      fontSize: 8,
      lineHeight: 1.5
    },
    tableH2: {
      fontFamily: "SpoqaHanSansNeo-Bold",
      fontSize: 8,
      lineHeight: 1.5
    },
    tableCaption: isKo ? {
      fontFamily: "SpoqaHanSansNeo-Regular",
      fontSize: 8,
      lineHeight: 1.5
    } : {
      fontFamily: "SpoqaHanSansNeo-Regular",
      fontSize: 6,
      lineHeight: 1.5
    }
  };
  return typeographyCommonForPDF;
};
export default typography;