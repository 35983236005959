var __jsx = React.createElement;
import React from "react";
import EmptyStates from "../EmptyStates";
import MaterialIcon from "../../Icon/MaterialIcon";
import { translateComponent } from "../../../locales";
import { withTranslation } from "../../../libs/i18n";

var NFTBalanceEmptyStates = function NFTBalanceEmptyStates(props) {
  var onDeposit = props.onDeposit,
      t = props.t;
  return __jsx(EmptyStates, {
    title: translateComponent(["nftBalanceEmptyStates", "title"], t),
    content: translateComponent(["nftBalanceEmptyStates", "description"], t),
    buttonProps: {
      startIcon: __jsx(MaterialIcon, {
        name: "file_download"
      }),
      onClick: onDeposit
    },
    buttonText: translateComponent(["nftBalanceEmptyStates", "submitButton"], t)
  });
};

export default withTranslation(["common", "component"])(NFTBalanceEmptyStates);