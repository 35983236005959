import _defineProperty from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
import _defaultTo from "lodash/defaultTo";

var _SDK_ENV, _HAECHI_ORGANIZATION_;

import getConfig from "next/config";
import { baseUrls } from "@haechi-labs/henesis-wallet-core/lib/utils/url";
import packageJson from "../../package.json";

var _defaultTo2 = _defaultTo(getConfig(), {
  serverRuntimeConfig: {},
  publicRuntimeConfig: {}
}),
    serverRuntimeConfig = _defaultTo2.serverRuntimeConfig,
    publicRuntimeConfig = _defaultTo2.publicRuntimeConfig;

var RELEASE_VERSION = publicRuntimeConfig.RELEASE_VERSION,
    HENESIS_URL_PATH = publicRuntimeConfig.HENESIS_URL_PATH,
    REACT_ENV = publicRuntimeConfig.REACT_ENV,
    NODE_ENV = publicRuntimeConfig.NODE_ENV,
    _publicRuntimeConfig$ = publicRuntimeConfig.PORT,
    PORT = _publicRuntimeConfig$ === void 0 ? "3000" : _publicRuntimeConfig$;
var HENESIS_API_SECRET = serverRuntimeConfig.HENESIS_API_SECRET,
    GCP_STORAGE_UPLOADER_CREDENTIAL_PATH = serverRuntimeConfig.GCP_STORAGE_UPLOADER_CREDENTIAL_PATH;
var reactEnv = REACT_ENV || "development";
var BASE_URL = {
  development: "https://app.dev.wallet.henesis.io",
  testnet: "https://app.test.wallet.henesis.io",
  "testnet-staging": "https://app.staging.test.wallet.henesis.io",
  production: "https://app.wallet.henesis.io",
  "production-staging": "https://app.staging.wallet.henesis.io"
};
var LOCAL_HENESIS_URL = HENESIS_URL_PATH ? HENESIS_URL_PATH : "http://localhost:".concat(PORT);
var HENESIS_URL = {
  development: "https://app.dev.wallet.henesis.io",
  testnet: "https://app.test.wallet.henesis.io",
  "testnet-staging": "https://app.staging.test.wallet.henesis.io",
  production: "https://app.wallet.henesis.io",
  "production-staging": "https://app.staging.wallet.henesis.io"
};
var GCLOUD_STORAGE = {
  development: {
    projectName: "henesis-wallet-dev",
    bucketName: "bkt-dev-billing",
    keyFile: "./henesis-dashboard-gcp-dev.json"
  },
  testnet: {
    projectName: "henesis-wallet-dev",
    bucketName: "bkt-dev-billing",
    keyFile: "./henesis-dashboard-gcp-dev.json"
  },
  "testnet-staging": {
    projectName: "henesis-wallet-dev",
    bucketName: "bkt-staging-testnet-billing",
    keyFile: GCP_STORAGE_UPLOADER_CREDENTIAL_PATH
  },
  production: {
    projectName: "henesis-wallet-prod",
    bucketName: "bkt-prod-mainnet-billing",
    keyFile: GCP_STORAGE_UPLOADER_CREDENTIAL_PATH
  },
  "production-staging": {
    projectName: "henesis-wallet-dev",
    bucketName: "bkt-dev-billing",
    keyFile: "./henesis-dashboard-gcp-dev.json"
  }
};
var testnetGAKey = "UA-126138188-8";
var productionGAKey = "UA-126138188-9";
var GA_KEY = {
  development: "",
  testnet: testnetGAKey,
  "testnet-staging": testnetGAKey,
  production: productionGAKey,
  "production-staging": productionGAKey
};
var SDK_ENV = (_SDK_ENV = {
  development: {
    // 1 dev
    url: baseUrls.get(1),
    env: 2
  },
  testnet: {
    env: 2
  }
}, _defineProperty(_SDK_ENV, "testnet-staging", {
  url: "https://staging.test.wallet.henesis.io/api/v2",
  env: 2
}), _defineProperty(_SDK_ENV, "production", {
  env: 3
}), _defineProperty(_SDK_ENV, "production-staging", {
  url: "https://staging.wallet.henesis.io/api/v2",
  env: 3
}), _SDK_ENV);
export var isProduction = REACT_ENV === "production" || REACT_ENV === "production-staging";
export var isTestnet = REACT_ENV === "testnet" || REACT_ENV === "testnet-staging";
export var isDevelopment = REACT_ENV === "development";
export var isTestnetAndDevelopment = isTestnet || isDevelopment;
export var isTestnetStaging = REACT_ENV === "testnet-staging";
export var isProductionStaging = REACT_ENV === "production-staging";
export var isDevelopmentAndStaging = isDevelopment || isTestnetStaging || isProductionStaging;
export var isNodeDevelopment = NODE_ENV === "development";
export var isNodeProduction = NODE_ENV === "production";
var isSetHenesisOrDevelopmentUrl = isNodeDevelopment || Boolean(HENESIS_URL_PATH);
export var isNodeProductionAndDevelopment = isDevelopment && isNodeProduction;
var HAECHI_ORGANIZATION_ID = (_HAECHI_ORGANIZATION_ = {
  development: "575a431dc73615a9e65648180bbd4fbb",
  testnet: "bb711450974bc873b66011881f395141"
}, _defineProperty(_HAECHI_ORGANIZATION_, "testnet-staging", "bb711450974bc873b66011881f395141"), _defineProperty(_HAECHI_ORGANIZATION_, "production", "31cafc79bd437e1b8e7b8209e399d3f3"), _defineProperty(_HAECHI_ORGANIZATION_, "production-staging", "31cafc79bd437e1b8e7b8209e399d3f3"), _HAECHI_ORGANIZATION_);
var firebaseProdConfig = {
  apiKey: "AIzaSyDaO10x8pBIYLE1PaI45lYv3yYabpPmfoI",
  authDomain: "heneiss-wallet-dashboard.firebaseapp.com",
  databaseURL: "https://heneiss-wallet-dashboard.firebaseio.com",
  projectId: "heneiss-wallet-dashboard",
  storageBucket: "heneiss-wallet-dashboard.appspot.com",
  messagingSenderId: "732282044905",
  appId: "1:732282044905:web:06a4a56fc1dcf740784b78",
  measurementId: "G-1BBZMXRMBM"
};
var DEVELOPMENT_DOCS_URL = {
  ko: "https://docs.henesis.io/",
  en: "https://docs.henesis.io/v/eng/"
};
export var env = {
  BASE_URL: BASE_URL[reactEnv],
  HENESIS_API_SECRET: HENESIS_API_SECRET,
  HENESIS_URL: isSetHenesisOrDevelopmentUrl ? LOCAL_HENESIS_URL : HENESIS_URL[reactEnv],
  NODE_ENV: NODE_ENV,
  SDK_ENV: SDK_ENV[reactEnv],
  REACT_ENV: reactEnv,
  GA_KEY: GA_KEY[reactEnv],
  FIREBASE_KEY: firebaseProdConfig,
  VERSION: packageJson.version,
  RELEASE_VERSION: RELEASE_VERSION,
  HAECHI_ORGANIZATION_ID: HAECHI_ORGANIZATION_ID[reactEnv],
  DEVELOPMENT_DOCS_URL: DEVELOPMENT_DOCS_URL,
  GCLOUD_STORAGE: isNodeDevelopment ? GCLOUD_STORAGE["development"] : GCLOUD_STORAGE[reactEnv]
};