import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from "react";

var SvgComponent = function SvgComponent(props) {
  return __jsx("svg", _extends({
    width: 17,
    height: 17,
    viewBox: "0 0 17 17",
    fill: "none"
  }, props), __jsx("rect", {
    y: 0.977295,
    width: 16.1044,
    height: 16,
    rx: 4,
    fill: "#F5405B"
  }), __jsx("path", {
    d: "M8.767 5.705h-1.58l.142 5.092h1.3l.138-5.092zM7.98 13.07c.455 0 .849-.38.852-.853a.863.863 0 00-.852-.845.849.849 0 100 1.698z",
    fill: "#fff"
  }));
};

export default SvgComponent;