import _flatten from "lodash/flatten";
import _groupBy from "lodash/groupBy";
import _filter from "lodash/filter";
import { getBlockchainsByLanguage } from "./blockchain";
export var filterNull = function filterNull(iterable) {
  if (!iterable) {
    return [];
  }

  return _filter(iterable, function (i) {
    return i !== null;
  });
};
export var filterEmpty = function filterEmpty(iterable) {
  if (!iterable) {
    return [];
  }

  return _filter(iterable, // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function (i) {
    return i !== null && i !== undefined && i !== "";
  });
};
export var orderByBlockchain = function orderByBlockchain(iterable) {
  var byBlockchain = _groupBy(iterable, function (item) {
    return item.blockchain;
  });

  return filterEmpty(_flatten(getBlockchainsByLanguage().map(function (blockchain) {
    return byBlockchain[blockchain];
  })));
};