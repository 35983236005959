import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from "react";
import EmptyStates from "./EmptyStates";
import MaterialIcon from "../Icon/MaterialIcon";
import { translateComponent } from "../../locales";
import { withTranslation } from "../../libs/i18n";

var TransferEmptyStates = function TransferEmptyStates(props) {
  var onDeposit = props.onDeposit,
      t = props.t;
  return __jsx(EmptyStates, _extends({
    title: translateComponent(["transferEmptyStates", "title"], t),
    content: translateComponent(["transferEmptyStates", "description"], t)
  }, onDeposit ? {
    buttonProps: {
      startIcon: __jsx(MaterialIcon, {
        name: "file_download"
      }),
      onClick: onDeposit
    },
    buttonText: translateComponent(["transferEmptyStates", "submitButton"], t)
  } : {}));
};

export default withTranslation()(TransferEmptyStates);