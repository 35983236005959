import _defineProperty from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray";
import _toConsumableArray from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray";
import _omit from "lodash/omit";
import _get from "lodash/get";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as Sentry from "@sentry/nextjs";
import { ErrorCode, makeWalletErrorByCode, errorMessageByCode, SDKError } from "./error";
import { isApiAccessTokenExpired, toDateTimeText, today } from "../utils/datetime";
import { makeSentryUriErrorName, reactotronApiResponse } from "../utils/uri";
import { getRootStore } from "../stores/Store";
import { isJSON } from "../utils/common";
import { isBrowser } from "../utils/browser";
export var isAccessTokenExpiredError = function isAccessTokenExpiredError(_ref) {
  var errorCode = _ref.errorCode,
      apiAccessToken = _ref.apiAccessToken;
  return !(ErrorCode.UNAUTHORIZED === errorCode && isApiAccessTokenExpired(apiAccessToken));
};
export var ignoreSentryErrorCodes = function ignoreSentryErrorCodes(errorCode) {
  return [ErrorCode.ACCESS_GUEST, ErrorCode.EMAIL_DOES_NOT_EXISTS, ErrorCode.INVALID_PASSWORD, ErrorCode.OTP_AUTHENTICATION_FAILED, ErrorCode.REQUESTED_GAS_PRICE_SAME, ErrorCode.ALREADY_EXISTS_WALLET_NAME, ErrorCode.NOT_VERIFIED_IP, ErrorCode.TIMEOUT_IP, ErrorCode.ALREADY_VERIFIED_IP, ErrorCode.INVALID_IP_VERIFY_REQUEST, ErrorCode.FAIL_TO_REPLACE_TRANSACTION, ErrorCode.ALREADY_PROCESSED_TRANSACTION, ErrorCode.ALREADY_REPLACED_TRANSACTION, ErrorCode.SAME_GAS_PRICE, ErrorCode.ALREADY_EXISTED_EMAIL_IN_SAME_ORGANIZATION, ErrorCode.ALREADY_EXISTED_EMAIL_IN_OTHER_ORGANIZATION, ErrorCode.OTP_HAS_RESET, ErrorCode.PASSWORD_HAS_RESET, ErrorCode.DELETED_ACCOUNT].some(function (code) {
    return code === errorCode;
  });
};
export var captureSDKError = function captureSDKError(_ref2) {
  var funcName = _ref2.funcName,
      args = _ref2.args,
      error = _ref2.error;

  var errorCode = _get(error, ["response", "data", "error", "code"], error.status);

  if (errorCode && ignoreSentryErrorCodes(errorCode)) {
    return;
  }

  Sentry.withScope(function (scope) {
    scope.setExtras({
      currentUrl: isBrowser ? window.location.href : "",
      localDatetime: toDateTimeText(today()),
      funcName: funcName,
      args: args,
      message: error.message
    });
    Sentry.captureException(new SDKError({
      name: "SDK-".concat(error.name, "-").concat(funcName),
      message: error.message,
      stack: error.stack
    }));
  });
};
export var setSentryUser = function setSentryUser(params) {
  Sentry.configureScope(function (scope) {
    scope.setUser(params);
  });
};
export var captureExceptionSentry = function captureExceptionSentry(_ref3) {
  var funcName = _ref3.funcName,
      args = _ref3.args,
      error = _ref3.error;
  var errorCode = error.status;

  if (ignoreSentryErrorCodes(errorCode)) {
    return;
  }

  Sentry.withScope(function (scope) {
    scope.setExtras({
      currentUrl: isBrowser ? window.location.href : "",
      localDatetime: toDateTimeText(today()),
      funcName: funcName,
      args: args,
      message: error.message
    });
    Sentry.captureException(new SDKError({
      name: "Browser-".concat(error.name, "-").concat(funcName),
      message: error.message,
      stack: error.stack
    }));
  });
};
export var addMonitor = function addMonitor(axiosInstance) {
  var interceptorsResponse = axiosInstance.interceptors.response;
  interceptorsResponse.handlers = [{
    fulfilled: function fulfilled(response) {
      return response;
    },
    rejected: function rejected(error) {
      var apiResponse = reactotronApiResponse(error.response);

      if (!apiResponse) {
        return Promise.reject(error);
      }

      var _apiResponse = _slicedToArray(apiResponse, 2),
          request = _apiResponse[0],
          response = _apiResponse[1];

      var errorCode = String(_get(error, ["response", "data", "error", "code"], ErrorCode.UNKOWN_ERROR));

      var errorMessage = _get(error, ["response", "data", "error", "message"], error.message);

      var requestId = _get(error, ["response", "data", "error", "requestId"], "request id empty");

      var apiAccessToken = getRootStore(false).authStore.apiAccessToken();

      if (!isAccessTokenExpiredError({
        errorCode: errorCode,
        apiAccessToken: apiAccessToken
      }) || ignoreSentryErrorCodes(errorCode)) {
        return Promise.reject(error);
      }

      Sentry.withScope(function (scope) {
        var data = _get(request, "data");

        var uriName = makeSentryUriErrorName(_get(request, "url", ""));
        scope.setExtras({
          currentUrl: isBrowser ? window.location.href : "",
          localDatetime: toDateTimeText(today()),
          request: typeof request === "number" ? request : _objectSpread(_objectSpread({}, _omit(request, "data")), {}, {
            headers: _omit(request.headers, "Authorization"),
            params: JSON.stringify(_get(request, "params"))
          }),
          data: isJSON(data) ? _omit(JSON.parse(data), ["password", "passphrase", "newPassphrase"]) : {},
          response: response,
          response_body_error: _get(response, ["body", "error"]),
          code: errorCode,
          message: errorMessageByCode(errorCode, errorMessage)
        });
        Sentry.captureException(makeWalletErrorByCode(_objectSpread(_objectSpread({
          errorCode: errorCode,
          defaultMessage: errorMessage,
          name: "API-".concat(uriName)
        }, errorCode === ErrorCode.UNKOWN_ERROR ? {
          message: errorMessage
        } : {}), {}, {
          requestId: requestId
        })));
      });
      return Promise.reject(error);
    }
  }].concat(_toConsumableArray(interceptorsResponse.handlers));
};