import _get from "lodash/get";
import _isNil from "lodash/isNil";
import { i18n } from "../libs/i18n";
import koJson from "./ko/common.json";
import enJson from "./en/common.json";
import koComponentJson from "./ko/component.json";
import enComponentJson from "./en/component.json";
import koComponentPDFJson from "./ko/component-pdf.json";
import enComponentPDFJson from "./en/component-pdf.json";
var translateLocale = new Map().set("en", "EN").set("ko", "KR");

var translate = function translate(names, t, options) {
  var key = "common:".concat(names.join("."));
  var keyIfNil = names.join(".");

  if (!t) {
    return translateByLanguage(names, i18n.language);
  }

  var targetKey = t(key, options);
  return _isNil(targetKey) || targetKey === keyIfNil ? translateByLanguage(names, i18n.language) : targetKey;
};

var translateComponent = function translateComponent(names, t, options) {
  var key = "component:".concat(names.join("."));
  var keyIfNil = names.join(".");

  if (!t) {
    return translateComponentByLanguage(names, i18n.language);
  }

  var targetKey = t(key, options);
  return _isNil(targetKey) || targetKey === keyIfNil ? translateComponentByLanguage(names, i18n.language) : targetKey;
};

var translateComponentPDF = function translateComponentPDF(names, t, options) {
  var key = "component-pdf:".concat(names.join("."));
  var keyIfNil = names.join(".");

  if (!t) {
    return translateComponentPDFByLanguage(names, i18n.language);
  }

  var targetKey = t(key, options);
  return _isNil(targetKey) || targetKey === keyIfNil ? translateComponentPDFByLanguage(names, i18n.language) : targetKey;
};

var translateByLanguage = function translateByLanguage(names, language) {
  var key = "".concat(names.join("."));

  if (language === "ko") {
    return _get(koJson, key);
  }

  return _get(enJson, key);
};

var translateComponentByLanguage = function translateComponentByLanguage(names, language) {
  var key = "".concat(names.join("."));

  if (language === "ko") {
    return _get(koComponentJson, key);
  }

  return _get(enComponentJson, key);
};

var translateComponentPDFByLanguage = function translateComponentPDFByLanguage(names, language) {
  var key = "".concat(names.join("."));

  if (language === "ko") {
    return _get(koComponentPDFJson, key);
  }

  return _get(enComponentPDFJson, key);
};

export { translate, translateComponent, translateComponentPDF, translateLocale, translateByLanguage, translateComponentByLanguage };