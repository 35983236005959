import _get from "lodash/get";
import _omit from "lodash/omit";
import { env } from "./env";
import { traverseObjectKeys, traverseObjectSliceStr } from "../utils/string";
export var URI_MAX_LENGTH = 20;
var EVENT_TYPE_MAX_LENGTH = 40;
export function logEvent(eventData, func) {
  var eventType = eventData.eventType;

  if (!eventType) {
    throw new Error("eventType is not provided!");
  }

  if (eventType.length > EVENT_TYPE_MAX_LENGTH) {
    throw new Error("".concat(eventType, " has over ").concat(EVENT_TYPE_MAX_LENGTH, " characters!"));
  }

  var isAllKeysUnderLength40 = traverseObjectKeys(_omit(eventData, ["eventType"]), function (key) {
    return key.length <= EVENT_TYPE_MAX_LENGTH;
  });

  if (!isAllKeysUnderLength40) {
    return;
  }

  var parameters = traverseObjectSliceStr(_omit(eventData, ["eventType"]), 100);
  func(eventData.eventType, parameters);
}

var getGtag = function getGtag() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return _get(globalThis, "gtag");
};

export var gTagEvent = function gTagEvent(eventData) {
  logEvent(eventData, function (eventType, param) {
    var gtag = getGtag();

    if (!gtag) {
      return;
    }

    gtag("event", eventType, param);
  });
};
export var gTagUser = function gTagUser(userId, role) {
  var gtag = getGtag();

  if (!gtag) {
    return;
  }

  gtag("config", env.GA_KEY, {
    user_id: userId,
    role: role
  });
};
export var gTagPageView = function gTagPageView(url) {
  var gtag = getGtag();

  if (!gtag) {
    return;
  }

  gtag("config", env.GA_KEY, {
    page_path: url
  });
};