import _isEmpty from "lodash/isEmpty";
import _identity from "lodash/identity";
import _pickBy from "lodash/pickBy";
import qs from "qs";
export function parseQuery(queryString) {
  var queryStringLastIndex = queryString.lastIndexOf("?");

  if (queryStringLastIndex === -1) {
    return null;
  }

  var queryParams = qs.parse(queryString.substring(queryStringLastIndex + 1));
  return queryParams;
}
export function stringifyQuery(params) {
  return qs.stringify(params);
}
export function makeQueryParams(params) {
  if (!params) {
    return "";
  }

  var filterEmptyParams = _pickBy(params, _identity);

  return !_isEmpty(filterEmptyParams) ? "?".concat(stringifyQuery(filterEmptyParams)) : "";
}
export var reactotronApiResponse = function reactotronApiResponse(source) {
  var _config$baseURL;

  if (!source) {
    return;
  }

  var DEFAULT_CONTENT_TYPES_RX = /^(image)\/.*$/i;
  var ignoreContentTypes = DEFAULT_CONTENT_TYPES_RX;
  var config = source.config || {};
  var request = {
    url: "".concat((_config$baseURL = config.baseURL) !== null && _config$baseURL !== void 0 ? _config$baseURL : "").concat(config.url),
    method: config.method,
    data: config.data || null,
    headers: config.headers,
    params: config.params || null
  };
  var responseHeaders = source.headers || {};
  var contentType = responseHeaders["content-type"] || responseHeaders["Content-Type"];
  var useRealBody = (typeof source.data === "string" || typeof source.data === "object") && !ignoreContentTypes.test(contentType || "");
  var body = useRealBody ? source.data : "~~~ skipped ~~~";
  var response = {
    body: body,
    status: source.status,
    headers: responseHeaders
  };
  return [request, response, 0];
};
export var makeSentryUriErrorName = function makeSentryUriErrorName(uri) {
  var path = uri.substring(uri.indexOf("api/"));
  var queryStringLastIndex = path.lastIndexOf("?");
  var uriName = queryStringLastIndex === -1 ? path.replace(/api\//g, "").replace(/\//g, "-").toUpperCase() : path.substring(0, queryStringLastIndex).replace(/api\//g, "").replace(/\//g, "-").toUpperCase();
  var SENTRY_URI_ERROR_MAX_LENGTH = 35;
  return uriName.substring(0, SENTRY_URI_ERROR_MAX_LENGTH);
};