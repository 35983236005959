var __jsx = React.createElement;
import React from "react";
import EmptyStates from "./EmptyStates";
import MaterialIcon from "../Icon/MaterialIcon";
import { palette } from "../../themes/palette";
import { translateComponent } from "../../locales";
import { withTranslation } from "../../libs/i18n";

var UserWalletsEmptyStates = function UserWalletsEmptyStates(props) {
  var onCreate = props.onCreate,
      isViewer = props.isViewer,
      t = props.t;
  return __jsx(EmptyStates, {
    title: translateComponent(["userWalletsEmptyStates", "title"], t),
    content: isViewer ? translateComponent(["userWalletsEmptyStates", "descriptionForViewer"], t) : translateComponent(["userWalletsEmptyStates", "description"], t),
    buttonProps: {
      startIcon: __jsx(MaterialIcon, {
        name: "add",
        fontSize: 20,
        color: palette.white.main
      }),
      onClick: onCreate
    },
    buttonText: isViewer ? "" : translateComponent(["userWalletsEmptyStates", "submitButton"], t)
  });
};

export default withTranslation()(UserWalletsEmptyStates);