import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from "react";

var SvgComponent = function SvgComponent(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none"
  }, props), __jsx("path", {
    d: "M13.5 13.416V20h-3v-6.584L2 4h20l-8.5 9.416z",
    fill: "#000"
  }));
};

export default SvgComponent;