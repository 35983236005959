import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from "react";

function SvgComponent(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 24,
    viewBox: "0 0 10 10",
    fill: "none"
  }, props), __jsx("path", {
    opacity: 0.3,
    d: "M1 0v9h9",
    stroke: "#000"
  }));
}

export default SvgComponent;