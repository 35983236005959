import _extends from "/packages/henesis-wallet-dashboard/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from "react";

var SvgComponent = function SvgComponent(props) {
  return __jsx("svg", _extends({
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none"
  }, props), __jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M7 2a3 3 0 00-3 3v17h16V5a3 3 0 00-3-3H7zM6 4h12v7H6V4zm5.998 9.54l1.61 2.251c.233.331.37.734.365 1.173 0 1.114-.877 2.014-1.975 2.014-1.094 0-1.976-.9-1.976-2.014 0-.439.137-.841.37-1.173l1.606-2.25z",
    fill: "#000"
  }));
};

export default SvgComponent;